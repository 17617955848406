import { Grid, IconButton } from '@mui/material';
import ProfileSection from './ProfileSection';
import MenuIcon from '@mui/icons-material/Menu';
import useWindowSize from 'hooks/useWindowSize';
import MenuRender from 'layout/Header/Menu';
import HeaderLogo from './Logo';
import Cart from './Cart';

interface HeaderProps {
  handleLeftDrawerToggle: () => void,
}

const Header = (props: HeaderProps): JSX.Element => {

  const windowSize = useWindowSize();
  const { handleLeftDrawerToggle } = props;

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
    >
      {
        (windowSize.isDown('md'))
          ? (
            <>
              <Grid item xs={'auto'}>
                <IconButton
                  size={'large'}
                  edge={'start'}
                  color={'primary'}
                  onClick={handleLeftDrawerToggle}
                  sx={{
                    fontSize: '2.5rem',
                  }}
                >
                  <MenuIcon fontSize={'inherit'} />
                </IconButton>
              </Grid>
            </>
          )
          : (
            <>
              <Grid item xs={'auto'}>
                <HeaderLogo />
              </Grid>
              <Grid item xs={'auto'}>
                <MenuRender />
              </Grid>
            </>
          )
      }
      <Grid item xs></Grid>
      <Cart />
      <Grid item xs={'auto'}>
        <ProfileSection />
      </Grid>
    </Grid>
  );
};

export default Header;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export interface ModalHelperState {
  isShow: boolean,
}

export const initialState: ModalHelperState = {
  isShow: false,
};

export const modalHelperSlice = createSlice({
  name: 'modalHelp',
  initialState: initialState,
  reducers: {
    setShowModalHelp: (state, action: PayloadAction<boolean>) => {
      state.isShow = action.payload;
    },
  },
});

export const { setShowModalHelp } = modalHelperSlice.actions;
export default modalHelperSlice.reducer;
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Link, Theme } from '@mui/material';
import { stageOmie } from 'services';
import dashboard from 'menu-items/dashboard';
import useAnalytics from 'hooks/useAnalytics';

const useStyles = makeStyles((theme: Theme) => ({
  buttonLogin: {
    height: '40%',
    borderRadius: '27px',
    minWidth: '160px',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary[800],
    textTransform: 'uppercase',
    fontWeight: 800,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

export default function ButtonLogin(): JSX.Element {

  const classes = useStyles();

  const analytics = useAnalytics('button_login_top', 'portal');

  const handleClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    analytics.click('click_login');
    
    let redirectTo = window.location.href;
    if (redirectTo.includes('/404')) {
      const urlStart = dashboard.children[0].url;
      redirectTo = redirectTo.replace('/404', urlStart);
    }
    
    window.location.href = `${stageOmie}/login/?redirect_to=${redirectTo}`;

  };
  
  return (
    <Link onClick={handleClick}>
      <Button className={classes.buttonLogin}>
        ENTRAR
      </Button>
    </Link>
  );
  
}

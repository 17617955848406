import { useState } from 'react';
import { Button, ButtonProps, CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { hexToRgb } from 'utils/converts';

const paddingButton = '6px 12px';

const useStylesContained = makeStyles((theme: Theme) => ({
  primary: {
    padding: paddingButton,
    textTransform: 'uppercase',
    backgroundColor: theme.palette.primary[800],
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary[800],
      backgroundColor: theme.palette.secondary.main,
    },
  },
  secondary: {
    padding: paddingButton,
    textTransform: 'uppercase',
    color: theme.palette.primary[800],
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary[800],
      color: theme.palette.secondary.main,
    },
  },
}));

const useStylesOutlined = makeStyles((theme: Theme) => ({
  primary: {
    padding: paddingButton,
    textTransform: 'uppercase',
    borderColor: theme.palette.primary[800],
    color: theme.palette.primary[800],
    '&:hover': {
      color: theme.palette.primary[800],
      borderColor: theme.palette.primary[800],
      backgroundColor: `rgba(${hexToRgb(theme.palette.primary[800])}, 0.04)`,
    },
  },
  secondary: {
    padding: paddingButton,
    textTransform: 'uppercase',
    borderColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.dark,
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.dark,
      backgroundColor: `rgba(${hexToRgb(theme.palette.secondary.main)}, 0.04)`,
    },
  },
}));

const useStylesText = makeStyles((theme: Theme) => ({
  primary: {
    padding: paddingButton,
    textTransform: 'uppercase',
    borderColor: theme.palette.primary[800],
    color: theme.palette.primary[800],
    '&:hover': {
      color: theme.palette.primary[800],
      borderColor: theme.palette.primary[800],
      backgroundColor: `rgba(${hexToRgb(theme.palette.primary[800])}, 0.04)`,
    },
  },
  secondary: {
    padding: paddingButton,
    textTransform: 'uppercase',
    borderColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.dark,
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.dark,
      backgroundColor: `rgba(${hexToRgb(theme.palette.secondary.main)}, 0.04)`,
    },
  },
}));

const useStyles = {
  'contained': useStylesContained,
  'outlined': useStylesOutlined,
  'text': useStylesText,
};

interface ButtonLoadingProps extends ButtonProps {
  isLoading?: boolean;
  autoLoading?: boolean;
  onClick?: () => Promise<void> | void;
  loadingMessage?: string;
  color?: 'primary' | 'secondary';
  variant?: 'contained' | 'outlined' | 'text';
}

export default function ButtonLoading(props: ButtonLoadingProps): JSX.Element {

  const {
    autoLoading = true,
    loadingMessage = 'Aguarde...',
    color = 'primary',
    variant = 'contained',
    ...rest
  } = props;

  const classes = useStyles[variant]();
  const [autoLoadingIsLoading, setAutoLoadingIsLoading] = useState(false);
  const isLoading = (autoLoading) ? autoLoadingIsLoading : props.isLoading || false;

  const handleClick = async (): Promise<void> => {
    if (props.onClick && !isLoading) {
      if (autoLoading) setAutoLoadingIsLoading(true);
      await props.onClick();
      if (autoLoading) setAutoLoadingIsLoading(false);
    }
  };

  return (
    <Button
      {...rest}
      onClick={handleClick}
      className={classes[color]}
      variant={variant}
    >
      {
        !isLoading && (
          props.children
        )
      }
      {
        isLoading && (
          <>
            <CircularProgress
              size={16}
              sx={{
                color: (theme: Theme) => theme.palette.primary.dark,
                marginRight: '12px',
              }}
            />
            {loadingMessage}
          </>
        )
      }
    </Button >
  );
}
import { IThemeOption } from 'themes';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function themeStatusPayment(theme: IThemeOption) {
  return {
    open: {
      backgroundColor: theme.colors.warningDark,
      color: '#fff',
    },
    paid: {
      backgroundColor: theme.colors.successDark,
      color: '#fff',
    },
    expired: {
      backgroundColor: theme.colors.errorMain,
      color: '#fff',
    },
    canceled: {
      backgroundColor: theme.colors.grey500,
      color: '#fff',
    },
    authorized: {
      backgroundColor: '#BFF6B0',
      color: '#095126',
    },
  };
}
import { IThemeOption } from 'themes';
import { OrderStatusLabel } from 'interfaces/orders';

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

type Color = RGB | RGBA | HEX | string;

type IThemeStatusOrders = {
  [key in OrderStatusLabel]: {
    backgroundColor: Color,
    color: Color,
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function themeStatusOrder(theme: IThemeOption): IThemeStatusOrders {
  return {
    confirmed: {
      backgroundColor: '#ff601f',
      color: '#fff',
    },
    invoiced: {
      backgroundColor: '#3a64fd',
      color: '#fff',
    },
    expired: {
      backgroundColor: theme.colors.errorMain,
      color: '#fff',
    },    
    pending: {
      backgroundColor: '#9bfb8f',
      color: '#095126',
    },    
    paid: {
      backgroundColor: '#007570',
      color: '#fff',
    },    
    delivered: {
      backgroundColor: '#00E2F4',
      color: theme.colors.primary800,
    },    
    cancelled: {
      backgroundColor: '#9e9e9e',
      color: '#fff',
    },    
  };
}
import { Grid, Theme } from '@mui/material';
import { ButtonLoginOmie, ButtonRegisterOmie } from 'components/ModalLogin/index';

const MenuLogin = (): JSX.Element | null => {

  return (
    <Grid item container sx={{ padding: '12px 12px 0 12px' }}
      justifyContent={'center'}
      alignItems={'center'}
      rowSpacing={1}
    >
      <Grid item xs={12}>
        <ButtonLoginOmie
          sx={{
            backgroundColor: (theme: Theme) => theme.palette.primary[800] + '!important',
            color: (theme: Theme) => theme.palette.primary.main + '!important',
            borderColor: (theme: Theme) => theme.palette.primary.main + '!important',
          }}  
        />
      </Grid>
      <Grid item xs={12} sx={{ padding: '12px 16px' }}>
        <ButtonRegisterOmie align={'center'} sx={{ color: '#fff', opacity: 0.8   }} />
      </Grid>
    </Grid>
  );
};

export default MenuLogin;
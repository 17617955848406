import { useEffect, useState } from 'react';
import { useRoutes, useLocation, matchPath, RouteObject } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import useAuth from 'hooks/useAuth';
import { defaultMetaTags } from 'utils/html-page-utils';
import useAnalytics from 'hooks/useAnalytics';
import useApp from 'hooks/useApp';

function removeBetaRoutes(routes: RouteObject[]): RouteObject[] {
  return routes.reduce((filteredRoutes, route) => {
    if (route.handle && route.handle.onlyBeta) {
      return filteredRoutes;
    }

    const newRoute: RouteObject = { ...route };

    if (route.children) {
      newRoute.children = removeBetaRoutes(route.children);
    }

    filteredRoutes.push(newRoute);
    return filteredRoutes;
  }, [] as RouteObject[]);
}

const AppRoutes = (): React.ReactElement | null => {
  const auth = useAuth();
  let routes = (auth.user) ? AuthenticatedRoute : UnauthenticatedRoute;

  if (!auth.isAuthenticated || auth.user?.app_ver !== 2) {
    routes = removeBetaRoutes(routes);
  }

  return useRoutes(routes);
};

const AppRoutesWrapper = ({ children }: { children: React.ReactElement }): React.ReactElement => {

  const location = useLocation();
  const [previousPath, setPreviousPath] = useState(document.referrer || null);
  const app = useApp();
  const analytics = useAnalytics(null, app.module);

  useEffect(() => {
    if (window.omie && window.omie.tracker) {
      if (window.location.pathname !== previousPath) {
        window.omie.tracker.referer = previousPath;
        setPreviousPath(window.location.pathname);
      }

      const matchOrdersPage = matchPath(
        { path: '/meus-pedidos' },
        window.location.pathname,
      );
      if (matchOrdersPage) {
        analytics.loadPage('orders_page', null, 'portal');
        return;
      }

      const matchMyAppsPage = matchPath(
        { path: '/meus-aplicativos' },
        window.location.pathname,
      );
      if (matchMyAppsPage) {
        analytics.loadPage('my_apps', null, 'my-apps');
        return;
      }

      const matchTreinamentosPage = matchPath(
        { path: '/treinamentos' },
        window.location.pathname,
      );
      if (matchTreinamentosPage) {
        analytics.loadPage('treinamentos', null, 'my-apps');
        return;
      }

      const matchHomePage = matchPath(
        { path: '/home' },
        window.location.pathname,
      );
      if (matchHomePage) {
        analytics.loadPage('home_page', null);
        return;
      }
      const matchHistoricoPage = matchPath(
        { path: '/historico-financeiro' },
        window.location.pathname,
      );
      if (matchHistoricoPage) {
        analytics.loadPage('historic_page', null, 'portal');
        return;
      }
      const matchRestriction = matchPath(
        { path: '/consulta-cnpj-cpf' },
        window.location.pathname,
      );
      if (matchRestriction) {
        analytics.loadPage('restriction_page', null, 'portal');
        return;
      }


      const matchProductPage = matchPath(
        { path: '/:provider/:productAlias' },
        window.location.pathname,
      );
      const matchCompanyPage = matchPath(
        { path: '/:provider' },
        window.location.pathname,
      );
      if (matchProductPage) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        analytics.loadPage('product_page', matchProductPage.params.productAlias!, 'portal');
      } else if (matchCompanyPage) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        analytics.loadPage('company_page', matchCompanyPage.params.provider!, 'portal');
      } else {
        const matchViewPage = matchPath(
          { path: 'view/:date/:token' },
          window.location.pathname,
        );
        if (matchViewPage) {
          // responsabilidade fica dentro da view
        } else {
          analytics.loadPage(null, window.location.pathname, 'portal');
        }
      }
    }

    defaultMetaTags();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return children;

};

export { AppRoutes, AppRoutesWrapper };
import axios from 'axios';
import { getNewToken } from 'hooks/useAuth';
import { LocalStorageService, Logger, stage } from 'services';
import PortalreCaptcha from 'services/recaptcha';
import { getRecaptchaToken } from 'utils/stage';
import { IApiConfig } from './interfaces';
import { recaptchaActionPortal } from 'store/constant';

const configs: IApiConfig = {
  prod: {
    baseURL: 'https://portalapi.omie.com.br/api/portal',
  },
  release: {
    baseURL: 'https://portalapinext.omie.com.br/api/portal',
  },
  dsv: {
    baseURL: 'https://portalapi.dsv.omie.com.br/api/portal',
  },
};
const configApi = configs[stage];

const api = axios.create({
  baseURL: configApi.baseURL,
});

const recaptcha = new PortalreCaptcha(getRecaptchaToken());

api.interceptors.request.use(async (config) => {

  if (config.url && config.recaptcha?.useRecaptcha !== false) {
    const action = config.recaptcha?.action || recaptchaActionPortal;
    const tokenRecaptcha = await recaptcha.execute(action, config.url);
    if (tokenRecaptcha && tokenRecaptcha !== '') {
      config.headers['g-response-token'] = tokenRecaptcha;
    }
  }

  const token = LocalStorageService.getAccessToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  const session = LocalStorageService.getSessionToken();
  if (session) {
    config.headers['X-Session-Id'] = session;
  }
  return config;

}, error => {
  Promise.reject(error);
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    Logger.error(error);
    if (!error.response) {
      try {
        Logger.error(error.toJSON());
      } catch (errorCatch) {
        Logger.error(error);
        Logger.error(errorCatch);
      }
      return Promise.reject({
        'data': {
          'status': 'gateway_timeout',
          'message': 'Não foi possível carregar os dados. Tente novamente mais tarde.',
        },
      });
    }

    const originalRequest = error.config;
    const status = error.response.status;

    if (status === 502) {
      try {
        Logger.error(error.toJSON());
      } catch (errorCatch) {
        Logger.error(error);
        Logger.error(errorCatch);
      }
      return Promise.reject({
        'data': {
          'status': 'bad_gateway',
          'message': 'Não foi possível carregar os dados. Tente novamente mais tarde.',
        },
      });
    }

    if ((status === 401 || status === 403) && !originalRequest._retry) {

      if (status === 403 && error.response.data.status === 'invalid_recaptcha_token') {
        return Promise.reject(error);
      }

      Logger.error(`Status: ${status} - ${error.response.data.message}`);

      if (originalRequest.url.includes('refresh-token')) {
        return Promise.reject(error);
      }
      originalRequest._retry = true;

      const accessToken = LocalStorageService.getAccessToken();
      const refreshToken = LocalStorageService.getRefreshToken();

      if (accessToken && refreshToken) {
        await getNewToken(accessToken, refreshToken, true);
        return api(originalRequest);
      }
    }

    // return Error object with Promise
    return Promise.reject(error);
  },
);

export { api };
import { makeStyles } from '@mui/styles';
import { Avatar, Grid, IconButton, Theme, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAnalytics from 'hooks/useAnalytics';
import useAuth from 'hooks/useAuth';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import useApp from 'hooks/useApp';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary[800],
    color: theme.palette.primary.main,
    border: '2px solid rgb(255 255 255 / 15%)!important',
  },
  textName: {
    margin: '5px 0 0 0',
    padding: 0,
    lineHeight: 1,
    color: '#fff',
  },
  textEmail: {
    margin: 0,
    padding: 0,
    lineHeight: 1,
    verticalAlign: 'middle !important',
    color: 'rgba(255, 255, 255, 0.5)',
  },
  buttonLogout: {
    width: '36px',
    height: '36px',
  },
}));

const MenuUser = (): JSX.Element | null => {

  const auth = useAuth();
  const app = useApp();
  const classes = useStyles();
  const navigate = useNavigate();
  const analytics = useAnalytics('sidebar_button', app.module);

  if (!auth.user) return null;

  const handleLogout = (): void => {
    analytics.click('logout');
    navigate('/logoff', { state: { redirectTo: window.location.href } });
  };

  return (
    <Grid item container sx={{ padding: '12px' }}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Grid item xs>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
          <Grid item xs={'auto'}>
            <Avatar alt={auth.user.first_name} className={classes.avatar}>
              <FontAwesomeIcon iconName={'user-alt'} color={'inherit'} />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              gutterBottom component="p"
              noWrap
              className={classes.textName}
            >
              <strong>{auth.user.first_name} {auth.user.last_name}</strong>
            </Typography>
            <span className={classes.textEmail}>
              <small>{auth.user.email}</small>
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={'auto'}>
        <Tooltip
          title={'Sair'}
          placement={'top'}
          arrow
        >
          <IconButton size={'medium'} className={classes.buttonLogout} onClick={handleLogout}>
            <FontAwesomeIcon
              iconName='right-from-bracket'
              size={'sm'}
              type={'light'}
              sx={{
                color: 'rgb(255 255 255 / 65%)',
              }}
            />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default MenuUser;
import { createContext } from 'react';

const IntercomContext = createContext({
  intercomElement: null,
  show: () => { },
  hide: () => { },
  boot: () => { },
  shutdown: () => { },
});

export { IntercomContext };

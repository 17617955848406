import { store } from 'store';
import { setNotificationCredit } from 'store/reducer/notification';
import { Logger } from './loggerService';
import { AppHash } from 'context/CompanyContext';
import { GetCompanyStoreResponse, GetCreditRequests, GetDashboardResponse, GetAPIDashBoard } from 'interfaces/api';
import { CreditData, CreditDataEtapas } from 'interfaces';

const DASHBOARD = 'dashboard';
const DASHBOARD_PAINEL = 'dashboard_painel';
const PROVIDERS = 'providers';
const CREDITS = 'credits';

class LocalStorageService {
  clearProviders(): void {
    const providers = this.getProviders();
    for (let i = 0; i < providers.length; i++) {
      localStorage.removeItem(providers[i]);
    }
    localStorage.removeItem(PROVIDERS);
  }

  clearDashboard(): void {
    localStorage.removeItem(DASHBOARD);
  }

  clearDashboardPainel(): void {
    localStorage.removeItem(DASHBOARD_PAINEL);
  }

  clearCredits(): void {
    localStorage.removeItem(DASHBOARD);
  }

  setProviders(providers: AppHash[]): void {
    if (!providers) {
      return this.clearProviders();
    }
    localStorage.setItem(PROVIDERS, JSON.stringify([...new Set(providers)]));
  }

  getProviders(): AppHash[] {
    const providers = localStorage.getItem(PROVIDERS);
    if (!providers) return [];

    return JSON.parse(providers);
  }

  setDashboard(dashboard: GetDashboardResponse): void {
    if (!dashboard) {
      return this.clearDashboard();
    }

    localStorage.setItem(DASHBOARD, JSON.stringify({
      ...dashboard,
      timestamp: new Date().getTime(),
    }));
  }

  setDashboardPainel(dashboard: GetAPIDashBoard): void {
    if (!dashboard) {
      return this.clearDashboardPainel();
    }

    localStorage.setItem(DASHBOARD_PAINEL, JSON.stringify({
      ...dashboard,
      timestamp: new Date().getTime(),
    }));
  }

  getProvider(appHash: AppHash): GetCompanyStoreResponse | null {
    const providerData = localStorage.getItem(appHash);
    if (!providerData) return null;
    return JSON.parse(providerData);
  }

  removeProvider(appHash: AppHash): void {
    const providers = this.getProviders();
    providers.splice(providers.indexOf(appHash), 1);
    this.setProviders(providers);
    localStorage.removeItem(appHash);
  }

  setProvider(appHash: AppHash, companyData: GetCompanyStoreResponse): void {

    if (!companyData) {
      this.removeProvider(appHash);
      return;
    }

    const providerStorage = this.getProvider(appHash);

    const providerData = {
      ...providerStorage,
      ...companyData,
      timestamp: new Date().getTime(),
    };
    const providers = this.getProviders();
    providers.push(appHash);
    this.setProviders(providers);
    localStorage.setItem(appHash, JSON.stringify(providerData));
  }

  getDashboard(): GetDashboardResponse | null {
    const dashboard = localStorage.getItem(DASHBOARD);
    if (!dashboard) return null;

    const dashboardJson = JSON.parse(dashboard);

    if (!dashboardJson.timestamp) return null;

    return dashboardJson;
  }

  getDashboardPainel(): GetAPIDashBoard | null {
    const dashboard = localStorage.getItem(DASHBOARD_PAINEL);
    if (!dashboard) return null;

    const dashboardJson = JSON.parse(dashboard);

    if (!dashboardJson.timestamp) return null;

    return dashboardJson;
  }

  setLoadingProvider(appHash: AppHash, isLoading: boolean): void {

    const providerStorage = this.getProvider(appHash);
    if (!providerStorage) return;

    providerStorage.isLoading = isLoading;
    this.setProvider(appHash, providerStorage);
  }

  getCredits(): GetCreditRequests | null {
    const credits = localStorage.getItem(CREDITS);
    if (!credits) return null;

    const creditsJson: GetCreditRequests = JSON.parse(credits);

    if (!creditsJson.timestamp) return null;

    const openeds = creditsJson.data.filter(credit => credit.opened === false).length;
    store.dispatch(setNotificationCredit(openeds));

    return creditsJson;
  }

  setCredits(credits: GetCreditRequests): void {

    const getLastUpdate = (credit: CreditData): CreditDataEtapas['updated_at'] => {
      return credit.etapas.filter(etapa => etapa.updated_at !== null).slice(-1)[0].updated_at;
    };

    const creditTemp = credits.data;
    const creditsStorage = localStorage.getItem(CREDITS);
    if (creditsStorage) {
      const creditsJson: GetCreditRequests = JSON.parse(creditsStorage);
      for (let i = 0; i < creditTemp.length; i++) {
        const credit = { ...creditTemp[i] };
        const creditStorage = creditsJson.data.filter(c => c.contract === credit.contract);

        if (creditStorage.length > 0) {

          if (getLastUpdate(credit) !== getLastUpdate(creditStorage[0])) {

            credit.opened = false;
            credit.lastUpdate = getLastUpdate(credit);
          } else {

            credit.opened = creditStorage[0].opened;
            credit.lastUpdate = getLastUpdate(credit);
          }

        } else {

          credit.opened = false;
          credit.lastUpdate = getLastUpdate(credit);
        }
        creditTemp[i] = credit;
      }

    } else {
      for (const credit of creditTemp) {
        credit.opened = false;
        credit.lastUpdate = getLastUpdate(credit);
      }
    }

    const openeds = creditTemp.filter(credit => credit.opened === false).length;
    store.dispatch(setNotificationCredit(openeds));

    localStorage.setItem(CREDITS, JSON.stringify({
      ...credits,
      timestamp: new Date().getTime(),
    }));
  }

  setOpenedContractCredit(contract: CreditData['contract']): void {
    const creditStorage = this.getCredits();

    if (!creditStorage) return;

    const creditStorageIndex = creditStorage.data.findIndex(
      creditStorageFind => creditStorageFind.contract === contract,
    );

    if (creditStorageIndex > -1) {
      const creditStorageData = creditStorage.data[creditStorageIndex];
      if (!creditStorageData.opened) {
        creditStorage.data[creditStorageIndex] = {
          ...creditStorageData,
          opened: true,
        };
        localStorage.setItem(CREDITS, JSON.stringify(creditStorage));
      }
      const openeds = creditStorage.data.filter(credit => credit.opened === false).length;
      store.dispatch(setNotificationCredit(openeds));
    }
  }

  clearCache(): void {
    this.clearDashboard();
    this.clearDashboardPainel();
    this.clearProviders();
    this.clearCredits();
    Logger.info('Cache limpo com sucesso');
  }
}

const CacheService = new LocalStorageService();

window.omie = window.omie || {};
window.omie.data = window.omie.data || {};
window.omie.data.cacheStorage = window.omie.data.cacheStorage || {};
window.omie.data.cacheStorage.clear = (): void => {
  CacheService.clearCache();
};

export { CacheService };
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import { Card, CardContent, CardHeader, Divider, Typography, CardActions, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';



const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  marginRight: '12px',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const MainCard = (props) => {

  const theme = useTheme();
  const {
    boxShadow,
    children,
    content = true,
    contentClass,
    contentStyle = {},
    darkTitle,
    secondary,
    shadow,
    sx = {},
    title,
    elevation,
    collapsed = false,
    startCollapsed = true,
    className,
    layoutFlat = false,
    ...others
  } = props;

  const [expanded, setExpanded] = useState(startCollapsed);

  const headerSX = {
    padding: '20px',
    '& .MuiCardHeader-action': { mr: 0 },
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card {...others} className={className}
      elevation={elevation}
      sx={{
        // border: border ? '1px solid' : 'none',
        border: !layoutFlat ? 'none' : '1px solid rgb(178 188 202 / 40%)',
        borderColor: !layoutFlat ? theme.palette.primary[200] + 75 : 'rgb(178 188 202 / 40%)',
        borderTop: !layoutFlat ? `3px solid ${theme.palette.primary[800]}` : '1px solid rgb(178 188 202 / 40%)',
        borderRadius: !layoutFlat ? '5px !important' : '7px !important',
        // eslint-disable-next-line max-len
        boxShadow: !layoutFlat ? '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);' : 'none',
        // ':hover': {
        //   boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
        // },
        '& .MuiCardHeader-title': {
          fontSize: !layoutFlat ? '18px' : '13px!important',
          fontWeight: !layoutFlat ? '500' : '600',
        },
        ...sx,
      }}
    >
      {
        (collapsed)
          ? (
            <CardActions disableSpacing
              id={'product-review'}
              onClick={handleExpandClick}
              sx={{
                padding: '0',
                cursor: 'pointer',
              }}
            >
              {/* card header and action */}
              {!darkTitle && title && 
                <CardHeader sx={headerSX} title={title} action={secondary} />}
              {darkTitle && title && (
                <CardHeader 
                sx={headerSX} 
                title={<Typography variant="h3">{title}</Typography>} action={secondary} />
              )}
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
          )
          : (
            <>
              {/* card header and action */}
              {!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
              {darkTitle && title && (
                <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
              )}
            </>
          )
      }

      {/* content & header divider */}
      {title && <Divider />}

      {
        (collapsed)
          ? (
            <Collapse in={expanded} timeout={'auto'} unmountOnExit>
              {/* card content */}
              {content && (
                <CardContent sx={contentStyle} className={contentClass}>
                  {children}
                </CardContent>
              )}
              {!content && children}
            </Collapse>
          )
          : (
            <>
              {/* card content */}
              {content && (
                <CardContent sx={contentStyle} className={contentClass}>
                  {children}
                </CardContent>
              )}
              {!content && children}
            </>
          )
      }


    </Card>
  );
};

MainCard.propTypes = {
  elevation: PropTypes.number,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  className: PropTypes.string,
  contentStyle: PropTypes.object,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  collapsed: PropTypes.bool,
  startCollapsed: PropTypes.bool,
  layoutFlat: PropTypes.bool,
};

export default MainCard;

import { TStage } from 'interfaces/app';

const LOCAL_DOMAINS = ['localhost', '127.0.0.1'];

function checkIsLocalhost(): boolean {
  if (LOCAL_DOMAINS.includes(window.location.hostname)) return true;

  const regexPrivateIp = /(^127\.)|(^10\.)|(^172\.1[6-9]\.)|(^172\.2[0-9]\.)|(^172\.3[0-1]\.)|(^192\.168\.)/;
  if (regexPrivateIp.test(window.location.hostname)) return true;

  return false;
}

const isLocalhost = checkIsLocalhost();

const getStage = (): TStage => {
  if (process.env.REACT_APP_STAGE) {
    return process.env.REACT_APP_STAGE as TStage;
  }
  return process.env.NODE_ENV === 'production' ? 'prod' : 'dsv';
};

const getStageOmie = (): string => {
  const stage = getStage();
  if (stage === 'prod') return 'https://app.omie.com.br';
  if (stage === 'release') return 'https://appnext.omie.com.br';
  return 'https://appdsv.omie.com.br';
};

const getRecaptchaToken = (): string => {
  return ['prod', 'release'].includes(getStage())
    ? '6Lf467QlAAAAAO-IGhpkiI4rhZOv6K3SxD3FX_Vz'
    : '6LcQCZUmAAAAAN2EOV-BqAmCPRNuVz7mRXir4amI';
};

export { getStage, isLocalhost, checkIsLocalhost, getRecaptchaToken, getStageOmie };

import { Logger } from './loggerService';

export default class PortalreCaptcha {
  private siteKey: string;

  constructor(siteKey: string) {
    this.siteKey = siteKey;
  }

  public async execute(action: string, url: string): Promise<string | null> {
    return new Promise<string>((resolve, reject) => {
      try {
        Logger.debug('[RECAPTCHA] Buscando token recaptcha para uso em:', url);        
        if (window.grecaptcha && window.grecaptcha.enterprise) {
          window.grecaptcha.enterprise.ready(() => {
            window.grecaptcha.enterprise.execute(this.siteKey, { action })
              .then((token: string) => {
                resolve(token);
              })
              .catch((error: any) => {
                console.error('[RECAPTCHA]', error);
                reject(null);
              });
          });
        } else {
          Logger.debug('[RECAPTCHA] grecaptcha.enterprise não está disponível');
          return resolve('');
        }
      } catch (e) {
        return reject(null);
      }
    });
  }

}
import { MenuItemGroup } from 'interfaces/menu-items';

const itemsMyApps: MenuItemGroup = {
  id: 'my-apps',
  title: '',
  type: 'group',
  children: [
    {
      title: 'Meus Aplicativos',
      type: 'item',
      url: '/meus-aplicativos',
      icon: 'table-cells',
      breadcrumbs: false,
      analytics: 'my_apps'
    },
    {
      title: 'Extrato Financeiro',
      type: 'item',
      url: 'https://app.omie.com.br/my-orders/',
      external: true,
      icon: 'money-check-dollar',
      breadcrumbs: false,
      analytics: 'financial_extract'
    },
    {
      title: 'Meus Treinamentos',
      type: 'item',
      url: '/treinamentos',
      icon: 'graduation-cap',
      breadcrumbs: false,
      analytics: 'trainings'
    },
  ],
};

export default itemsMyApps;
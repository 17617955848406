import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CustomizationState {
  theme: string,
  fontFamily: string,
  borderRadius: number,
  opened: boolean,
  isOpen: string[],
  creditTooltipOpened: boolean,
}

export const initialState: CustomizationState = {
  theme: 'light',
  fontFamily: "'Roboto', sans-serif",
  borderRadius: 12,
  opened: false,
  isOpen: [],
  creditTooltipOpened: false,
};

export const customizationSlice = createSlice({
  name: 'customization',
  initialState: initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
    },
    setLeftNav: (state, action: PayloadAction<boolean>) => {
      state.opened = action.payload;
    },
    setMenuOpen: (state, action: PayloadAction<string>) => {
      state.isOpen = [action.payload];
    },
    setCreditTooltip: (state, action: PayloadAction<boolean>) => {
      state.creditTooltipOpened = action.payload;
    },
  },
});

export const {
  setTheme,
  setLeftNav,
  setMenuOpen,
  setCreditTooltip,
} = customizationSlice.actions;
export default customizationSlice.reducer;
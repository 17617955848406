
import useAuth from 'hooks/useAuth';
import ButtonUser from './ButtonUser';
import ButtonLogin from './ButtonLogin';

const ProfileSection = (): JSX.Element => {
  const auth = useAuth();
  if (!auth.user) return <ButtonLogin />;
  return <ButtonUser user={auth.user} />;
};

export default ProfileSection;

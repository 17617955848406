import { combineReducers } from 'redux';
import customizationReducer from './customization';
import dashboardReducer from './dashboard';
import dashboardPainelReducer from './dashboardPainel';
import modalHelpReducer from './modalHelp';
import notificationReducer from './notification';
import myappsReducer from './myapps'

const reducer = combineReducers({
  customization: customizationReducer,
  dashboard: dashboardReducer,
  dashboardPainel: dashboardReducer,
  modalHelp: modalHelpReducer,
  notifications: notificationReducer,
  myapps: myappsReducer
});

export default reducer;
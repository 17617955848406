import React, { useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import useAnalytics from 'hooks/useAnalytics';
import CloseIcon from '@mui/icons-material/Close';
import { sleep } from 'utils';
import { useNavigate } from 'react-router-dom';

interface AlertConsultaRestricaoProps {
  handleClose: () => void;
  showClose: boolean;
}

const STORAGE_ALERT_KEY = 'consulta_text_random';

const TEXT_RANDOM = [
  'Reduza riscos e inadimplência com a Consulta CNPJ e CPF. É fácil e rápido.',
  'Proteja suas vendas: consulte restrições em CPF e CNPJ de forma rápida e simples.',
  'Mais segurança para suas vendas: confira rapidamente restrições em CNPJ e CPF.',
];

export default function AlertConsultaRestricao(props: AlertConsultaRestricaoProps): JSX.Element | null {
  const analytics = useAnalytics('alert_consulta_restricao', 'portal');

  const random = TEXT_RANDOM[Math.floor(Math.random() * 3)];
  //Logger.info(random);

  const navigate = useNavigate();

  const handleClick = (): void => {
    if (window.location.href.indexOf('consulta-cnpj-cpf') >= 0 && window.location.href.indexOf('planos') < 0) {
      return;
    }

    analytics.click('click_alert: ' + random);
    navigate('/consulta-cnpj-cpf');
  };

  const handleClose = async (): Promise<void> => {
    analytics.click('Fechando alerta');
    props.handleClose();
    await sleep(1);
    // localStorage.setItem(STORAGE_ALERT_KEY, JSON.stringify(false));
  };

  useEffect(() => {
    localStorage.setItem(STORAGE_ALERT_KEY, random);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container sx={{ height: '100%', cursor: 'pointer' }}>
      <Grid container onClick={handleClick}>
        <Grid item xs>
          <Typography variant={'body1'}>{random}</Typography>
        </Grid>
        {props.showClose && (
          <Grid item xs={'auto'} sx={{ height: '20px', cursor: 'pointer' }}>
            <CloseIcon fontSize="small" onClick={handleClose} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

import { Box, CircularProgress, Typography, Grid, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { footerHeight, headerHeight } from 'store/constant';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: `calc(100vh - ${(headerHeight + (2 * footerHeight))}px)`,
    width: '100%',
    margin: '0px',
    padding: '0px',
  },
  container: {
    height: '100%',
  },
}));

interface IPropsLoadingSpinner {
  label?: string,
  size?: number,
  top?: number,
  fullScreen?: boolean,
  textAlign?: 'left' | 'center' | 'right';
}

const LoadingSpinner = (props: IPropsLoadingSpinner): JSX.Element => {

  const classes = useStyles();
  const theme = useTheme();
  const mode = theme.palette.mode;
  const {
    label, size = 40,
    fullScreen = false,
    textAlign = 'center',
    ...rest
  } = props;

  const color = (mode === 'light')
    ? theme.palette.primary.dark
    : theme.palette.secondary.dark;

  const top = (rest.top) ? rest.top.toString() : `calc(50% - ${size / 2}px)`;

  const Spinner = (): JSX.Element => (
    <CircularProgress size={size}
      sx={{
        color: color,
      }}
    />
  );

  const BoxSpinner = (): JSX.Element => (
    <Box sx={{
      width: '100%',
      height: '100%',
      position: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div
        style={{
          position: 'relative',
          top: top,
          left: '50%',
          transform: 'translate(-50%, 0%)',
          textAlign: textAlign,
        }}
      >
        <Spinner />
        {
          (label) && (
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 999,
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary"
                sx={{
                  backgroundColor: 'rgba(255,255,255,1)',
                  padding: '0',
                }}
              >
                {label}
              </Typography>
            </Box>
          )
        }
      </div>
    </Box>
  );

  if (fullScreen) {
    return (
      <div className={classes.wrapper} aria-label={'Loading'}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          className={classes.container}
        >
          <Grid item xs={12}>
            <BoxSpinner />
          </Grid>
        </Grid>
      </div>
    );
  }

  return <BoxSpinner />;
};

export default LoadingSpinner;
/* eslint-disable max-len */
import { CompanyProduct } from 'interfaces';

// interface MetaTagsProduct {
//   property: string;
//   key: string | null;
//   default: string;
// }

// const metaTagsProduct: MetaTagsProduct[] = [
//   {
//     property: 'og:site_name',
//     key: 'title',
//     default: 'Portal Omie',
//   },
//   {
//     property: 'og:title',
//     key: 'title',
//     default: 'Portal Omie - Sistema de Gestão ERP Online - PMEs e grandes empresas',
//   },
//   {
//     property: 'og:type',
//     key: null,
//     default: 'website',
//   },
//   {
//     property: 'og:description',
//     key: 'description',
//     // eslint-disable-next-line max-len, max-len
//     default: 'Omie combina sistema de gestão online, serviços financeiros e educação empreendedora para impulsionar contadores e empresas de todos os portes.',
//   },
//   {
//     property: 'og:image',
//     key: 'images',
//     default: '',
//   },
//   {
//     property: 'og:url',
//     key: null,
//     default: '',
//   },
// ];

export function addProductMetaTags(product: CompanyProduct): void {

  document.title = product.title + ' | Portal Omie';

  // for (let i = 0; i < metaTagsProduct.length; i++) {
  //   const metaTag = metaTagsProduct[i];

  //   const meta = document.createElement('meta');
  //   meta.setAttribute('property', metaTag.property);

  //   if (metaTag.property === 'og:image') {
  //     const urlImage = (product.images.length > 0) ? product.images[0].url : metaTag.default;
  //     meta.setAttribute('content', JSON.stringify(urlImage));
  //   } if (metaTag.property === 'og:url') {
  //     meta.setAttribute('content', location.href);
  //   } else {
  // eslint-disable-next-line max-len
  //     meta.setAttribute('content', (metaTag.key !== null) ? product[metaTag.key] || metaTag.default : metaTag.default);
  //   }

  //   document.getElementsByTagName('head')[0].appendChild(meta);
  // }

}

export function defaultMetaTags(): void {
  document.title = 'Portal Omie';

  // const metas = [...document.getElementsByTagName('meta')];
  // console.info(metas);

  // for (let i = 0; i < metaTagsProduct.length; i++) {
  //   const metaTag = metaTagsProduct[i];

  //   const tagsScoped = metas.filter((item) => {

  //     const itemAttributes = [...item.attributes];
  //     const itemAttributesKeys = Object.keys(itemAttributes);

  //     for (let j = 0; j < itemAttributesKeys.length; j++) {
  //       const itemAttribute = itemAttributes[j];
  //       console.info(itemAttribute);
  //     }

  //     console.info(itemAttributes, itemAttributesKeys);

  //     return false;
  //   });

  // }
}
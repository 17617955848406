import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import useAnalytics from 'hooks/useAnalytics';

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.primary[800],
    overflow: 'hidden',
    position: 'relative',
  },
  tagTitle: {
    color: theme.palette.common.white,
  },
  tagLine: {
    color: theme.palette.common.white,
    opacity: 0.9,
  },
  link: {
    '&:hover': {
      'cursor': 'pointer',
    },
  },
  button: {
    color: theme.palette.primary[800],
    backgroundColor: theme.palette.secondary.main,
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

const OmieCard = () => {
  const classes = useStyles();
  const analytics = useAnalytics('card_adversiting', 'my-apps');

  const handleClick = (event) => {
    event.preventDefault();
    analytics.click('Omie');
    // eslint-disable-next-line max-len
    window.open('https://www.omie.com.br/portal/?utm_campaign=portal-plg&utm_source=bt-conheca&utm_medium=propagando-omie', '_blank').focus();
  };

  return (
    <Grid item sx={{ textAlign: 'center', padding: '0px 16px 16px 16px' }}>
      <Typography variant="subtitle2" className={classes.tagLine}>
        Sistema de gestão direto ao ponto, pra resolver seu dia.
      </Typography>
      <Typography variant="subtitle2" className={classes.tagLine}>
        Do tamanho que sua empresa precisa.
      </Typography>
      <Typography variant="subtitle2" className={classes.link} onClick={handleClick}>
        www.omie.com.br
      </Typography>
    </Grid>
  );
};

export default OmieCard;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Button, Box, Dialog, Slide, Theme, SxProps, Link } from '@mui/material';
import Logo from 'ui-component/Logo';
import AnimateButton from 'components/AnimateButton';
import OmieFavicon from 'assets/images/omie_favicon.png';
import MainCard from 'ui-component/cards/MainCard';
import { stageOmie } from 'services';
import dashboard from 'menu-items/dashboard';
import { TransitionProps } from '@mui/material/transitions';
import backgroundLogin from 'assets/images/bg-login.jpg';
import useAnalytics from 'hooks/useAnalytics';

const useStyles = makeStyles((theme: Theme) => ({
  buttonLoggin: {
    fontSize: '1rem',
    fontWeight: 500,
    backgroundColor: theme.palette.primary['800'],
    borderColor: theme.palette.secondary.dark,
    color: '#fff',
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
    },
  },
  buttonRegister: {
    fontSize: '1rem',
    fontWeight: 500,
    backgroundColor: 'transparent',
    borderColor: theme.palette.primary['800'],
    color: theme.palette.primary['800'],
    border: '1px solid',
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      borderColor: theme.palette.secondary.dark,
    },
  },
  loginIcon: {
    marginRight: '16px',
    [theme.breakpoints.down('md')]: {
      marginRight: '8px',
    },
  },
  card: {
    maxWidth: '900px',
    width: '100%',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
    },
    [theme.breakpoints.down('xl')]: {
      maxWidth: '900px',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '500px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'auto',
    },
  },
  content: {
    padding: `${theme.spacing(5)} !important`,
    [theme.breakpoints.down('xl')]: {
      padding: `${theme.spacing(3)} !important`,
    },
  },
  bgLogin: {
    backgroundImage: `url(${backgroundLogin})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    position: 'absolute',
    left: '410px',
    right: '0px',
    top: '0px',
    bottom: '0px',
  },
  link: {
    fontSize: '11px',
    padding: '0 5px',
    cursor: 'pointer',
  },
  containerLogin: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '-20px',
    },
  },
  containerInnerModal: {},
  noMobile: {
    [theme.breakpoints.down('md')]: {
      display: 'none !important',
    },
  },
  boxMessage: {
    width: '575px',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  interface Directions {
    direction?: 'left' | 'right' | 'up' | 'down' | undefined;
  }

  const directions = ['left', 'right', 'up', 'down'];
  const direction = directions[Math.floor(Math.random() * directions.length)] as Directions['direction'];

  return <Slide direction={direction} ref={ref} {...props} />;
});

interface IButtonLoginOmie {
  sx?: SxProps<Theme> | undefined;
  returnTo?: string | undefined;
}

const ButtonLoginOmie = (props: IButtonLoginOmie): JSX.Element => {
  const classes = useStyles();
  const { sx } = props;

  const analytics = useAnalytics('login_button', 'portal');

  const signUpOmie = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();

    let returnTo = props.returnTo;

    if (returnTo === undefined) {
      returnTo = window.location.href;
    }

    if (returnTo.includes('/404')) {
      const urlStart = dashboard.children[0].url;
      returnTo = returnTo.replace('/404', urlStart);
    }

    analytics.click(`redirect_to_${returnTo}`);
    window.location.href = `${stageOmie}/login/?redirect_to=${returnTo}`;
  };

  return (
    <AnimateButton>
      <Button
        disableElevation
        fullWidth
        className={classes.buttonLoggin}
        onClick={signUpOmie}
        size="large"
        variant="contained"
        sx={{
          ...sx,
        }}
      >
        <img src={OmieFavicon} alt="omie" width="20px" className={classes.loginIcon} /> Conectar no Portal Omie
      </Button>
    </AnimateButton>
  );
};

interface IButtonRegisterOmie {
  sx?: SxProps<Theme> | undefined;
  returnTo?: string | undefined;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
}

const ButtonRegisterOmie = (props: IButtonRegisterOmie): JSX.Element => {
  const classes = useStyles();

  const { sx = {} } = props;

  const analytics = useAnalytics('register_button', 'portal');

  const signInOmie = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();

    let returnTo = props.returnTo;

    if (returnTo === undefined) {
      returnTo = window.location.href;
    }

    if (returnTo.includes('/404')) {
      const urlStart = dashboard.children[0].url;
      returnTo = returnTo.replace('/404', urlStart);
    }

    analytics.click(`redirect_to_${returnTo}`);
    window.location.href = `${stageOmie}/register/?redirect_to=${returnTo}`;
  };

  return (
    <AnimateButton>
      <Button
        disableElevation
        fullWidth
        className={classes.buttonRegister}
        onClick={signInOmie}
        size="large"
        variant="contained"
        sx={{
          ...sx,
        }}
      >
        Criar uma nova conta
      </Button>
    </AnimateButton>
  );
};

interface IModalLogin {
  open: boolean;
  handleClose?: () => void;
  message: string | JSX.Element;
  href?: string | undefined;
  title?: string | undefined;
}

const ModalLogin = (props: IModalLogin): JSX.Element => {
  const classes = useStyles();

  const { open, handleClose, message, href, title } = props;

  const analytics = useAnalytics('modal_login', 'portal');

  const handleClickTerms = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    analytics.click('open_terms');
    window
      .open('https://www.omie.com.br/seguranca-e-privacidade/politica-de-privacidade-omie-solucao.pdf', '_blank')
      ?.focus();
  };

  const handleClickPolicy = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    analytics.click('open_policy');
    window
      .open('https://www.omie.com.br/seguranca-e-privacidade/politica-de-privacidade-omie-solucao.pdf', '_blank')
      ?.focus();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
          maxWidth: '900px',
        },
      }}
    >
      <MainCard className={classes.card} contentClass={classes.content} sx={{ borderTop: '0' }}>
        <Grid container spacing={2} alignItems="center" className={classes.containerLogin}>
          <Grid
            item
            lg={5}
            md={5}
            sm={12}
            xs={12}
            sx={{ paddingLeft: '25px!important', paddingBottom: '5px!important', paddingTop: '15px!important' }}
          >
            <Grid container>
              <Grid item xs={12} sx={{ mb: 5 }}>
                <Logo redirect={false} aspect={1.4} />
              </Grid>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Typography sx={{ color: '#000', fontWeight: '600', fontSize: '24px' }}>
                  {title != undefined ? title : 'Acesse agora'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="column" spacing={3} className={classes.containerInnerModal}>
                  {message && (
                    <Grid item xs={12} container>
                      <Box sx={{ height: '67px' }} className={classes.boxMessage}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontSize: '15px', color: '#9b9b9b', fontWeight: '400', lineHeight: '22px' }}
                        >
                          {message}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12} mt={2}>
                    <ButtonLoginOmie returnTo={href} />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        textAlign: 'center',
                        borderTop: '1px solid #9b9b9b',
                      }}
                    >
                      <Typography
                        sx={{
                          display: 'inline-block',
                          color: '#9b9b9b',
                          fontWeight: '400',
                          background: '#fff',
                          position: 'relative',
                          top: '-7px',
                          padding: '0 10px',
                        }}
                      >
                        OU
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: '5px!important' }}>
                    <ButtonRegisterOmie returnTo={href} sx={{ color: '#fff' }} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    mt={1}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <Link onClick={handleClickTerms} underline="none" color="inherit" className={classes.link}>
                      Termos e Condições
                    </Link>
                    &bull;
                    <Link onClick={handleClickPolicy} underline="none" color="inherit" className={classes.link}>
                      Política de Privacidade
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7} className={classes.noMobile}>
            <Box className={classes.bgLogin}></Box>
          </Grid>
        </Grid>
      </MainCard>
    </Dialog>
  );
};

ModalLogin.propTypes = {
  open: PropTypes.bool.isRequired,
  returnTo: PropTypes.string,
  handleClose: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default ModalLogin;
export { ButtonLoginOmie, ButtonRegisterOmie };

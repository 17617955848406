import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  style: (props) => props,
});

export type TIconType = 'solid' | 'regular' | 'light' | 'duotone' | 'brands' | 'thin';
export type TIconName = string;
export type TIconSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 'xs' | 'sm' | 'lg' | 'xl';
export type TIconStyle = {
  margin?: string;
  padding?: string;
};


const getClassFromType = (type?: TIconType): string => {

  if (!type) return 'fa-solid';

  switch (type) {
    case 'solid':
      return 'fa-solid';
    case 'regular':
      return 'fa-regular';
    case 'light':
      return 'fa-light';
    case 'duotone':
      return 'fa-duotone';
    case 'brands':
      return 'fa-brands';
    case 'thin':
      return 'fa-thin';
    default:
      return 'fa-solid';
  }
};

const getClassSizeFromSize = (size?: TIconSize): string => {
  if (['xs', 'sm', 'lg', 'xs'].find(s => s === size)) return `fa-${size}`;
  return `fa-${size}x `;
};

export interface IIconProps {
  iconName: TIconName;
  color?: string;
  zoom?: string;
  alt?: string;
  size?: TIconSize;
  type?: TIconType;
  style?: TIconStyle;
  fixedWidth?: boolean;
  sx?: React.CSSProperties;
  onClick?: (event: React.MouseEvent) => void;
}

const FontAwesomeIcon = React.memo(function Icon(props: IIconProps): JSX.Element | null {

  const propsStyle = {
    color: props.color ? `${props.color} !important` : 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    zoom: (props.zoom) ? props.zoom : null,
    ...props.sx,
  };

  const classes = useStyles(propsStyle);

  if (!props.iconName || props.iconName === '') {
    return null;
  }

  const type = getClassFromType(props.type);
  const size = getClassSizeFromSize(props.size || 1);
  const fixedWidth = props.fixedWidth ? 'fa-fw ' : ' ';
  const className = `${type} fa-${props.iconName} ${size}${fixedWidth}${classes.style}`;

  const handleClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    if (props.onClick) {
      return props.onClick(event);
    }
  };

  const iconElement = React.createElement('span', {
    className: className.trim(),
    alt: props.alt || '',
    style: props.style || {},
  });

  return (
    <i key={Math.random()} onClick={handleClick} title={props.alt}>
      {iconElement}
    </i>
  );
});

export default FontAwesomeIcon;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MyApps } from 'interfaces';

export interface MyAppsState {
    isTrial: boolean,
    appTrial: MyApps | undefined,
    isTrainingOnline: boolean,
}

export const initialState: MyAppsState = {
    isTrial: false,
    appTrial: undefined,
    isTrainingOnline: false
};

export const myAppsSlice = createSlice({
  name: 'myApps',
  initialState: initialState,
  reducers: {
    setIsTrialShow: (state, action: PayloadAction<boolean>) => {
      state.isTrial = action.payload;
    },
    setAppTrial: (state, action: PayloadAction<MyApps | undefined>) => {
      state.appTrial = action.payload;
    },
    setIsTrainingOnline: (state, action: PayloadAction<boolean>) => {
        state.isTrainingOnline = action.payload;
    },
  },
});

export const { setIsTrialShow, setAppTrial, setIsTrainingOnline } = myAppsSlice.actions;
export default myAppsSlice.reducer;
import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import useStyles from 'themes/baseStyles';
import useAnalytics from 'hooks/useAnalytics';
import CloseIcon from '@mui/icons-material/Close';
import { sleep } from 'utils';

const STORAGE_ALERT_KEY = 'credit-alert';

export function showAlertCredit(): boolean {
  const storageKey = localStorage.getItem(STORAGE_ALERT_KEY);
  return storageKey !== null ? JSON.parse(storageKey) : true;
}

interface AlertCreditProps {
  handleClose: () => void;
  showClose: boolean;
}

export default function AlertCredit(props: AlertCreditProps): JSX.Element | null {
  const classes = useStyles();
  const analytics = useAnalytics('alert_credit', 'portal');
  const showAlert = showAlertCredit();

  if (!showAlert) return null;

  const handleClick = (): void => {
    analytics.click('Abrindo simulador de crédito');
    // eslint-disable-next-line max-len
    const url = '/simulador';
    window.open(url, '_blank')?.focus();
  };

  const handleClose = async (): Promise<void> => {
    analytics.click('Fechando alerta');
    props.handleClose();
    await sleep(1);
    // localStorage.setItem(STORAGE_ALERT_KEY, JSON.stringify(false));
  };

  return (
    <Container sx={{ height: '100%' }}>
      <Grid container>
        <Grid item xs>
          <Typography variant={'body1'}>
            Precisando de um fôlego?{' '}
            <strong className={classes.alertOmieLink} onClick={handleClick}>
              Clique aqui para simular agora um crédito parcelado para sua empresa.{' '}
            </strong>
            É tudo 100% digital, e rápido. {':-)'}
          </Typography>
        </Grid>
        {props.showClose && (
          <Grid item xs={'auto'} sx={{ height: '20px', cursor: 'pointer' }}>
            <CloseIcon fontSize="small" onClick={handleClose} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

import { createContext } from 'react';
import { IProviderCart } from 'interfaces/cart';
import { addressUserDefault } from 'store/constant';
import { ResponseError } from 'interfaces/api';

export const defaultContext: IProviderCart = {
  isLoading: true,
  items: [],
  provider: null,
  addItem: async () => { },
  reOrder: async () => ({ status: 'success' } as ResponseError),
  changeItemQuantity: async () => { },
  deleteItem: async () => { },
  deleteUnavailableItem: async () => { },
  clearAllItems: async () => { },
  addAddress: async () => addressUserDefault,
  updateAddress: async () => {},
  addresses: [],
  selectedAddress: null,
  selectedBillData: null,
  changeSelectedAddress: async () => { },
  changeSelectedBillData: async () => { },
  isSyncing: false,
  syncingLocalCart: async () => { },
  shippingCalculate: async () => null,
  methodPaymentSelected: 0,
  setMethodPaymentSelected: async () => { },
  sendOrder: async () => ({ status: 'success' } as ResponseError),
  showModalDuplicate: false,
  toggleModalDuplicate: () => { },
};

export const CartContext = createContext<IProviderCart>(defaultContext);
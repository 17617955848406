import {
  Breakpoint,
  Dialog, DialogContent, DialogTitle,
  Grid, IconButton, Typography, useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import FontAwesomeIcon, { TIconSize } from 'components/FontAwesomeIcon';
import ButtonLoading from 'components/Buttons/ButtonLoading';
import useCart from 'hooks/useCart';
import useWindowSize from 'hooks/useWindowSize';

interface CartModalDuplicateProps {
  onClose: () => void,
}

function getSizeByWindowSize(currentBreakpoint: Breakpoint): TIconSize {

  switch (currentBreakpoint) {
    case 'xs':
      return 4;
    case 'sm':
      return 6;
    default:
      return 8;
  }

}

export default function CartModalDuplicate(props: CartModalDuplicateProps): JSX.Element {

  const { onClose } = props;
  const theme = useTheme();
  const cart = useCart();
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const handleClear = async (): Promise<void> => {
    await cart.clearAllItems()
      .then(() => {
        cart.toggleModalDuplicate();
      });
  };

  const handleGoToCart = (): void => {
    navigate('/meu-carrinho');
    cart.toggleModalDuplicate();
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle
        sx={{
          padding: '16px 24px',
          fontSize: '24px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs>
            Você só pode adicionar produtos de um fornecedor por vez
          </Grid>
          <Grid item xs={'auto'}>
            <IconButton
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container sx={{ textAlign: 'center' }} spacing={2} p={4}>
          <Grid item xs={12} sm={4} md={5} container
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item xs={'auto'}>
              <FontAwesomeIcon
                iconName='cart-circle-xmark'
                size={getSizeByWindowSize(windowSize.currentBreakpoint)}
                type={'light'}
                color={theme.palette.primary[800]}
                sx={{
                  margin: 'auto',
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm md spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'subtitle1'}>
                Já existem produtos de outro fornecedor no seu carrinho.<br />
                Deseja limpar o carrinho e adicionar este produto?
              </Typography>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <ButtonLoading
                  color={'primary'}
                  variant="contained"
                  onClick={handleClear}
                  loadingMessage={'Limpando carrinho...'}
                  autoLoading={true}
                >
                  <ClearAllIcon
                    sx={{ fontSize: '1.25rem', marginRight: '8px' }}
                  />
                  Limpar carrinho
                </ButtonLoading>
              </Grid>
              <Grid item xs={12}>
                <ButtonLoading
                  color={'primary'}
                  variant="outlined"
                  onClick={handleGoToCart}
                >
                  <DeliveryDiningIcon
                    sx={{ fontSize: '1.25rem', marginRight: '8px' }}
                  />
                  Ir para o Carrinho
                </ButtonLoading>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
import React, { useState, useEffect } from 'react';
import { useNavigate, matchPath, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Tooltip, Theme, Fade } from '@mui/material';
import useAuth from 'hooks/useAuth';
import OmieLogo from 'assets/images/omie_logo_novo.png';
import useStyles from 'themes/baseStyles';
import useApp from 'hooks/useApp';
import { boxShadowDefault } from 'store/constant';
import useAnalytics from 'hooks/useAnalytics';
import AlertOmie from './AlertOmie';
import AlertCredit from './AlertCredit';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { setCreditTooltip } from 'store/reducer/customization';
import ModalLogin from 'components/ModalLogin/index';

export const useStylesProvider = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: '1100',
  },
  tooltip: {
    borderRadius: '0',
    background: 'none',
    margin: 0,
    padding: 0,
    maxWidth: '100%',
    marginTop: '19px !important',
    backgroundColor: theme.palette.secondary.main,
    // backgroundColor: 'red',
    color: theme.palette.primary[800],
    // zIndex: '1099 !important',
  },
  container: {
    boxShadow: boxShadowDefault,
    width: '100vw',
    padding: '8px 16px',
    [theme.breakpoints.only('lg')]: {
      padding: '12px 30px',
    },
    [theme.breakpoints.only('xl')]: {
      padding: '14px 0',
    },
  },
  divImg: {
    width: '150px',
    height: '100%',
    cursor: 'pointer',
  },
}));

export default function HeaderLogo(): JSX.Element {

  const app = useApp();
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useAuth();
  const classesProvider = useStylesProvider();
  const analytics = useAnalytics('logo_omie', app.module);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [matchViewPage, setMatchViewPage] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const auth = useAuth();
  const [needLogin, setNeedLogin] = useState(false);

  // const showAlert = showAlertCredit();
  const showAlert = useSelector((state: RootState) => state.customization.creditTooltipOpened);

  useEffect(() => {

    const matchViewPageValidate = matchPath(
      { path: 'view/:date/:token' },
      window.location.pathname,
    );

    if (Boolean(matchViewPageValidate) !== tooltipOpen && showAlert) {
      setTooltipOpen(Boolean(matchViewPageValidate));
    } else if (Boolean(matchViewPageValidate) && !showAlert && tooltipOpen) {
      setTooltipOpen(false);
    }

    if (Boolean(matchViewPageValidate) !== matchViewPage) {
      setMatchViewPage(Boolean(matchViewPageValidate));
    }

    if (!Boolean(matchViewPageValidate) && showAlert) {
      dispatch(setCreditTooltip(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, showAlert]);

  const handleClick = (): void => {

    if (!auth.isAuthenticated) {
      analytics.click('login');
      setNeedLogin(true);
      return;
    }

    if (app.module === 'portal') {
      analytics.click('redirect_to_home');
      navigate((user) ? '/home' : '/');
    } else {
      analytics.click('redirect_to_my-apps');
      navigate((user) ? '/meus-aplicativos' : '/');
    }
  };

  const handleClose = (): void => {
    if (!matchViewPage || !showAlert) setTooltipOpen(false);
  };

  const handleOpen = (): void => {
    if (!matchViewPage || !showAlert) setTooltipOpen(true);
  };

  const handleCloseCredit = (): void => {
    setTooltipOpen(false);
  };

  const titleComponent = matchViewPage && showAlert
    ? (
      <div className={classesProvider.container}>
        <AlertCredit handleClose={handleCloseCredit} showClose={true} />
      </div>
    )
    : (app.module === 'portal') && (
      <div className={classesProvider.container}>
        <AlertOmie />
      </div>
    );

    const handleCloseModalLogin = (): void => {
      setNeedLogin(false);
    };

  return (
    <>
      {
        needLogin && (
          <ModalLogin open={true} message={'Visualize rapidamente seu histórico financeiro, notas fiscais emitidas, 2ª via de boletos e muito mais!'} handleClose={handleCloseModalLogin} />
        )
      }
      <Tooltip
        title={titleComponent}
        placement="bottom"
        classes={{ tooltip: classesProvider.tooltip, popper: classesProvider.popper }}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 400 }}
        open={tooltipOpen}
        onClose={handleClose} onOpen={handleOpen}
      >
        <div className={classesProvider.divImg} onClick={handleClick}
          style={{ paddingLeft: '8px' }}
        >
          <img
            src={OmieLogo}
            alt={'Omie'}
            className={classes.logoSize}
          />
        </div>
      </Tooltip>
    </>
    
  );
}

export const maskCellphone = (value: string): string => {
  value = value.slice(0, 15);                          //Define o limite de caracteres
  value = value.replace(/\D/g, '');                    //Remove tudo o que não é dígito
  value = value.replace(/^(\d{2})(\d)/g, '($1) $2');   //Coloca parênteses em volta dos dois primeiros dígitos
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');      //Coloca hífen entre o quarto e o quinto dígitos
  return value;
};

export const maskPhone = (value: string): string => {
  value = value.slice(0, 14);                          //Define o limite de caracteres
  value = value.replace(/\D/g, '');                    //Remove tudo o que não é dígito
  value = value.replace(/^(\d{2})(\d)/g, '($1) $2');   //Coloca parênteses em volta dos dois primeiros dígitos
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');      //Coloca hífen entre o terceiro e o quarto dígitos
  return value;
};

export const maskZipcode = (value: string): string => {
  value = value.slice(0, 9);                          //Define o limite de caracteres
  value = value.replace(/\D/g, '');                    //Remove tudo o que não é dígito
  value = value.replace(/(\d)(\d{3})$/, '$1-$2');      //Coloca hífen entre o terceiro e o quarto dígitos
  return value;
};

interface IMaskMoney {
  valueString: string;
  valueFloat: number;
}

export const maskMoney = (value: string): IMaskMoney => {

  let valueString = '';
  let valueFloat = 0;

  // Remove any non-numeric characters from the input
  const userInput = value.replace(/[^0-9]/g, '');

  if (userInput === '') {
    // If the input is empty, set the formatted value to "R$ 0,00"
    valueString = 'R$ 0,00';
    valueFloat = 0;
  } else {
    // Convert the input to a number and divide by 100 to get the value in BRL
    const userInputAsNumber = parseFloat(userInput) / 100;

    // Format the number as BRL currency
    const formattedNumber = 
    `R$ ${userInputAsNumber.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, '$1.')}`;

    // Update the state with the formatted value and the user input
    valueString = formattedNumber;
    valueFloat = userInputAsNumber;
  }

  return { valueString: valueString, valueFloat: valueFloat };

};
import { createContext } from 'react';
import type { Breakpoint } from '@mui/material';

export interface WindowSize {
  width: number,
  height: number,
  currentBreakpoint: Breakpoint,
  isDown: (breakpoint: Breakpoint) => boolean,
  isUp: (breakpoint: Breakpoint) => boolean,
}

const WindowSizeContext = createContext<WindowSize>({
  width: window.innerWidth,
  height: window.innerHeight,
  currentBreakpoint: 'xs',
  isDown: () => true,
  isUp: () => true,
});

export { WindowSizeContext };

const dashboard = {
  id: 'dashboard',
  title: '',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Meu Painel',
      type: 'item',
      url: '/home',
      icon: 'building',
      breadcrumbs: false,
    },
    {
      id: 'orders',
      title: 'Meus Pedidos',
      type: 'item',
      url: '/meus-pedidos',
      icon: 'cart-shopping',
      breadcrumbs: false,
    },
    {
      id: 'historic',
      title: 'Histórico Financeiro',
      type: 'item',
      url: '/historico-financeiro',
      icon: 'receipt',
      breadcrumbs: false,
    },
  ],
};

export default dashboard;

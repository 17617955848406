import { useContext, useEffect, useState } from 'react';
import { ICreditContext } from 'interfaces/app';
// import LoadingSpinner from 'components/LoadingSpinner';
import { CreditContext } from 'context/CreditContext';
import { getCreditRequests } from 'resources';
import { Logger } from 'services';
import useAuth from './useAuth';
import useApp from './useApp';

const useCreditApp = (): ICreditContext => {

  const [isLoading, setLoading] = useState(true);
  const [notification, setNotification] = useState<ICreditContext['notification']>(null);
  const [credit, setCredit] = useState<ICreditContext['credit']>(null);
  const auth = useAuth();
  const app = useApp();

  useEffect(() => {
    (async (): Promise<void> => {

      if (app.module === 'portal' && auth.user) {
        const response = await getCreditRequests(false);
        const { status, message } = response;

        if (status === 'success' && response.timestamp) {
          setCredit(response.data as ICreditContext['credit']);
        } else if (status === 'fatal_error') {
          // setApiError(response as ResponseError);
        } else if (message) {
          Logger.error(message);
        } else {
          Logger.error('Erro desconhecido');
        }
      }

      if (isLoading) setLoading(false);

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, app.module]);

  return {
    isLoading,
    notification,
    credit,
  };
};

export function ProviderCredit({ children }: { children: JSX.Element }): JSX.Element {
  const credit = useCreditApp();

  // if (credit.isLoading) {
  //   Logger.debug('Aguardando useCreditApp');
  //   return <LoadingSpinner fullScreen />;
  // }

  // Logger.debug('useCreditApp finalizado');

  return (
    <CreditContext.Provider value={credit}>
      {children}
    </CreditContext.Provider>
  );
}

const useCredit = (): ICreditContext => useContext(CreditContext);

export default useCredit;
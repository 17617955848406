import { Grid, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useAnalytics from 'hooks/useAnalytics';
import OmieLogo from 'assets/images/omie_logo_novo.png';
import useApp from 'hooks/useApp';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '0 12px',
    borderColor: '#9e9e9e',
    color: '#9e9e9e',
    cursor: 'pointer',
    '&:hover': {
      '& > *': {
        color: '#001e27',
        borderColor: theme.palette.secondary.dark + ' !important',
        '-webkit-filter': 'brightness(0.2)',
        filter: 'brightness(0.2)',
      },
    },
  },
  containerLogo: {
    border: '1px solid',
    borderColor: 'inherit',
    padding: '8px 12px',
    borderRadius: '8px',
    color: 'inherit',
  },
  poweredLabel: {
    margin: '0 8px 0 0',
    padding: 0,
    fontWeight: '500',
    color: 'inherit',
  },
  logo: {
    filter: 'grayscale(100%)',
    '-webkit-filter': 'grayscale(100%)',
  },
  aquiClick: {
    fontWeight: '700',
    color: 'inherit',
  },
}));

export default function Footer(): JSX.Element | null {

  const classes = useStyles();
  const app = useApp();
  const analytics = useAnalytics('footer_action', app.module);  

  if (app.module !== 'portal') return null;

  const handleClick = async (): Promise<void> => {
    analytics.click('powered_by_omie');
    window.open('http://www.omie.com.br/?utm_source=erp&utm_medium=boleto&utm_campaign=loop', '_blank')?.focus();
  };

  const aspect = 2.5;
  const width = 150 / aspect;
  const height = 45.453 / aspect;

  return (
    <Grid
      item
      xs={12} sm={12} md={'auto'}
      container
      alignItems={'center'}
      justifyContent={'center'}
      className={classes.container}
      id={'footer-container'}
      onClick={handleClick}
    >
      <Grid item
        xs={'auto'} sm={'auto'} md={'auto'}
        container
        alignItems={'center'}
        justifyContent={'center'}
        className={classes.containerLogo}
      >
        <Grid item xs={'auto'}>
          <Typography variant="caption" className={classes.poweredLabel}>
            powered by
          </Typography>
        </Grid>
        <Grid item xs={'auto'}>
          <img
            src={OmieLogo}
            alt={'Omie'}
            width={`${width}px`}
            height={`${height}px`}
            className={classes.logo}
          />
        </Grid>
      </Grid>
      <Grid item
        xs={9} sm md={'auto'}
        display={{
          xs: 'none',
          sm: 'block',
        }}
        sx={{
          textAlign: 'center !important',
        }}
      >
        <Typography variant="caption" align={'center'}
          sx={{
            margin: '0 0 0 8px',
            padding: 0,
            fontWeight: '500',
            // textAlign: 'left',
          }}
        >
          Sistema de gestão completo para seu negócio com emissão de boletos a R$ 1,99 e pix gratuitos -{' '}
          conheça <strong className={classes.aquiClick}>aqui</strong>.
        </Typography>
      </Grid>
    </Grid>
  );
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DashboardPainelState {
  isLoading: boolean,
}

export const initialState: DashboardPainelState = {
  isLoading: false,
};

export const dashboardPainelSlice = createSlice({
  name: 'dashboardPainel',
  initialState: initialState,
  reducers: {
    setLoadingDashboardPainel: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoadingDashboardPainel } = dashboardPainelSlice.actions;
export default dashboardPainelSlice.reducer;
import { Badge, BadgeProps, CircularProgress, Grid, IconButton, Theme } from '@mui/material';
import { keyframes, styled, useTheme } from '@mui/material/styles';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import useApp from 'hooks/useApp';
import useCart from 'hooks/useCart';
import { Link, useNavigate } from 'react-router-dom';
import useAnalytics from 'hooks/useAnalytics';

const getAnimation = (): any => keyframes`
  from,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(10px, -10px, 0);
    transform: translate3d(10px, -10px, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(10px, -35px, 0) scaleY(1.1);
    transform: translate3d(10px, -35px, 0) scaleY(1.1);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(10px, -20px, 0) scaleY(1.05);
    transform: translate3d(10px, -20px, 0) scaleY(1.05);
  }

  80% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(10px, -10px, 0) scaleY(0.95);
    transform: translate3d(10px, -10px, 0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(10px, -10px, 0) scaleY(1.02);
    transform: translate3d(10px, -10px, 0) scaleY(1.02);
  }
`;

const StyledBadgeAnimation = styled(Badge)<BadgeProps>(({ theme }: { theme: Theme }) => ({
  '& .MuiBadge-badge': {
    right: -5,
    top: 13,
    padding: '0 4px',
    animation: `${getAnimation()} 2s ease-in`,
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }: { theme: Theme }) => ({
  '& .MuiBadge-badge': {
    right: -5,
    top: 13,
    padding: '0 4px',
  },
}));

const CartLoading = (): JSX.Element => {
  return (
    <CircularProgress
      size={'12px'}
      sx={{
        color: (theme: Theme) => theme.palette.primary[800],
      }}
    />
  );
};

export default function Cart(): JSX.Element | null {

  const theme = useTheme();
  const navigate = useNavigate();
  const cart = useCart();
  const app = useApp();

  const analytics = useAnalytics('header_button', 'portal');

  if (app.module !== 'portal') return null;
  if (cart.isLoading) return null;
  if (cart.items.length === 0) return null;

  const handleClick = (): void => {
    analytics.click(`header_button_cart`);
    navigate('/meu-carrinho');
  };

  const quantities = cart.items.reduce((accumulator, currentItem) => accumulator + currentItem.quantity, 0);

  return (
    <Grid item xs={'auto'} mr={3}>
      <div onClick={handleClick}>
        <IconButton aria-label="cart"
          LinkComponent={Link}
          href={'/meu-carrinho'}
        >
          {
            cart.isSyncing
            ? <StyledBadge
                badgeContent={<CartLoading />}
                color="secondary"
              >
                <FontAwesomeIcon
                  iconName={'cart-shopping'}
                  type={'light'}
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                />
              </StyledBadge>
            : <StyledBadgeAnimation
                badgeContent={quantities}
                color="secondary"
              >
                <FontAwesomeIcon
                  iconName={'cart-shopping'}
                  type={'light'}
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                />
              </StyledBadgeAnimation>
          }
          
        </IconButton>
      </div>
    </Grid>
  );
}
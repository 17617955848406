import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import OmieLogo from '../assets/images/omie_logo_novo.png';
import useStyles from 'themes/baseStyles';
import useApp from 'hooks/useApp';
import ModalLogin from 'components/ModalLogin/index';
import useAnalytics from 'hooks/useAnalytics';

interface ILogo {
  aspect?: number, 
  redirect?: boolean
}

const Logo = (props: ILogo): JSX.Element => {

  const { aspect = 1, redirect = true } = props;
  const app = useApp();
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useAuth();

  const auth = useAuth();
  const [needLogin, setNeedLogin] = React.useState(false);

  const analytics = useAnalytics('logo_omie', app.module);
  
  const handleClick = (event: React.MouseEvent): void => {

    if (!auth.isAuthenticated) {
      analytics.click('login');
      setNeedLogin(true);
      return;
    }

    if (!redirect) {
      return;
    }

    if (app.module === 'portal') {
      analytics.click('redirect_to_home');
      navigate((user) ? '/home' : '/');
    } else {
      analytics.click('redirect_to_my-apps');
      navigate((user) ? '/meus-aplicativos' : '/');
    }
  };

  const handleCloseModalLogin = (): void => {
    setNeedLogin(false);
  };

  const width = (150 / aspect);

  return (
    <>
      {
        needLogin && (
          <ModalLogin open={true} message={'Visualize rapidamente seu histórico financeiro, notas fiscais emitidas, 2ª via de boletos e muito mais!'} handleClose={handleCloseModalLogin} />
        )
      }
      <span className={classes.logoSize}>
        <img
          src={OmieLogo}
          alt={'Omie'}
          style={{ 
            width: `${width}px`,
            cursor: redirect ? 'pointer' : 'default',
          }}
          onClick={handleClick}
        />
      </span>
    </>
    
  );
};

export default Logo;

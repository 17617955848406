/* eslint-disable @typescript-eslint/no-unused-vars */
import { IThemeOption } from 'themes';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function themeStatusCredit(theme: IThemeOption) {
  return {
    aguardando_analise: {
      backgroundColor: '#f1c40f',
      color: '#fff',
    },
    nao_aprovado: {
      backgroundColor: '#2c3e50',
      color: '#fff',
    },
    aguardando_assinatura_contrato: {
      backgroundColor: '#f1c40f',
      color: '#fff',
    },
    contrato_nao_assinado: {
      backgroundColor: '#2c3e50',
      color: '#fff',
    },
    aguardando_aprovacao_credito: {
      backgroundColor: '#2ecc71',
      color: '#fff',
    },
    processando_credito: {
      backgroundColor: '#2ecc71',
      color: '#fff',
    },
    credito_liberado: {
      backgroundColor: '#27ae60',
      color: '#fff',
    },
    problemas_conta_corrente: {
      backgroundColor: '#c0392b',
      color: '#fff',
    },
    contrato_quitado: {
      backgroundColor: '#ecf0f1',
      color: '#27ae60',
    },
    contrato_quitado_antecipadamente: {
      backgroundColor: '#ecf0f1',
      color: '#27ae60',
    },
    nova_proposta_sugerida: {
      backgroundColor: '#f39c12',
      color: '#fff',
    },
    nova_proposta_recusada: {
      backgroundColor: '#2c3e50',
      color: '#fff',
    },
    nova_proposta_aceita: {
      backgroundColor: '#7f8c8d',
      color: '#fff',
    },
    renegociacao_solicitado: {
      backgroundColor: '#27ae60',
      color: '#fff',
    },
    renegociacao_pendente_pagamento: {
      backgroundColor: '#2ecc71',
      color: '#fff',
    },
    contrato_renegociado: {
      backgroundColor: '#ecf0f1',
      color: '#27ae60',
    },
  };
}

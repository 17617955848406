import PageError from 'components/PageError';
import React from 'react';
import { Logger } from 'services';

interface ErrorBoundaryProps {
  children: React.ReactElement;
}

interface ErrorState {
  error: null | Error,
  errorInfo: null | React.ErrorInfo,
}

const PageHasBeenForceRefreshed = 'page-has-been-force-refreshed';

const retryPageIfChunkLoadError = (error: Error): void => {

  if (error.message?.includes('Loading chunk')) {
    Logger.warning('É um erro de ChunkLoadError');

    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(PageHasBeenForceRefreshed) || 'false',
    ) as boolean;

    Logger.warning('pageHasAlreadyBeenForceRefreshed');
    Logger.warning(pageHasAlreadyBeenForceRefreshed);

    if (!pageHasAlreadyBeenForceRefreshed) {
      Logger.debug('Forçando reload');
      return window.location.reload();
    } else {
      window.localStorage.setItem(PageHasBeenForceRefreshed, 'false');
    }
  } else {
    Logger.warning('Não é um erro de ChunkLoadError');
    Logger.error(error);
  }

  // reporting service
  window.omie.tracker.appCrash(error.message, error.stack);
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {

    retryPageIfChunkLoadError(error);

    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

  }

  render(): React.ReactElement {
    if (this.state.error) {
      return <PageError error={this.state.error} errorInfo={this.state.errorInfo} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
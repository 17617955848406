import React from 'react';
import { useSelector } from 'react-redux';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { AppRoutesWrapper, AppRoutes } from 'routes';
import themes from './themes';
import NavigationScroll from './layout/NavigationScroll';
import { RootState } from 'store';
import { ProviderAuth } from 'hooks/useAuth';
import { ProviderCart } from 'hooks/useCart';
import { ProviderApp } from 'hooks/useApp';
import { ProviderCredit } from 'hooks/useCredit';
import { ProviderIntercom } from 'hooks/useIntercom';
import { ProviderHotjar } from 'hooks/useHotjar';

export default function App(): JSX.Element {

  const customization = useSelector((state: RootState) => state.customization);

  return (
    <ProviderAuth>
      <ProviderApp>
        <ProviderHotjar />
        <ProviderIntercom>
          <ProviderCredit>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                  <ProviderCart>
                    <AppRoutesWrapper>
                      <AppRoutes />
                    </AppRoutesWrapper>
                  </ProviderCart>
                </NavigationScroll>
              </ThemeProvider>
            </StyledEngineProvider>
          </ProviderCredit>
        </ProviderIntercom>
      </ProviderApp>
    </ProviderAuth>
  );
}


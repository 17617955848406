import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Chip,
  Paper,
  Popper,
} from '@mui/material';
import Transitions from 'ui-component/extended/Transitions';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuHeader from './MenuHeader/index';

const useStyles = makeStyles((theme) => ({
  userName: {
    ...theme.typography.h4,
    paddingLeft: '12px',
    color: `${theme.palette.primary[800]}!important`,
  },
  profileChip: {
    alignItems: 'center',
    borderRadius: '27px',
    transition: 'all .2s ease-in-out',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    '&[aria-controls="menu-list-grow"], &:hover': {
      borderColor: `${theme.palette.primary.dark}!important`,
      background: `${theme.palette.primary.dark}!important`,
      color: theme.palette.primary.light,
    },
    '& svg': {
      stroke: theme.palette.primary[800],
    },
  },
  profileLabel: {
    lineHeight: 0,
    padding: '12px',
  },
}));

const ButtonUser = ({ user }) => {

  const classes = useStyles();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = React.useRef(null);
  const openMenu = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Chip
        classes={{ label: classes.profileLabel }}
        className={classes.profileChip}
        icon={(
          <p className={classes.userName}>
            {user.first_name}
          </p>
        )}
        label={
          <SettingsIcon
            sx={{
              color: theme.palette.primary.main,
              strokeWidth: '1.4',
              width: '1.4rem',
              height: '1.4rem',
            }}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={openMenu ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="secondary"
      />
      <Popper
        placement="bottom-end"
        open={openMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        sx={{ zIndex: 1600 }}
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={openMenu} {...TransitionProps}>
            <Paper>
              <MenuHeader
                handleClose={handleClose}
                open={openMenu}
                anchorEl={anchorRef.current}
              />
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ButtonUser;
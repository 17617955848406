import {
  EventTrackingContext,
  EventTrackingDescription, EventTrackingOptions,
  EventTrackingClientOrigin,
} from 'services';

class ContextException extends Error {
  constructor(message: string) {
    super();
    this.message = message;
    this.name = 'ContextException';
  }
}

interface UseAnalyticsMethods {
  click: (
    description: EventTrackingDescription,
    options?: EventTrackingOptions,
  ) => void,
  download: (
    description: EventTrackingDescription,
    options?: EventTrackingOptions,
  ) => void,
  search: (
    description: EventTrackingDescription,
    options?: EventTrackingOptions,
  ) => void,
  loadPage: (
    setContext: EventTrackingContext,
    description: EventTrackingDescription,
    setOrigin?: EventTrackingClientOrigin,
    options?: EventTrackingOptions,
  ) => void,
}

const useAnalytics = (
  context: EventTrackingContext | null,
  origin: EventTrackingClientOrigin,
): UseAnalyticsMethods => {

  if (context === undefined) throw new ContextException('Context is required');
  if (origin === undefined) throw new ContextException('Origin is required');

  const click = (
    description: EventTrackingDescription,
    options?: EventTrackingOptions,
  ): void => {
    window.omie.tracker.click(context, description, origin, options);
  };

  const download = (
    description: EventTrackingDescription,
    options?: EventTrackingOptions,
  ): void => {
    window.omie.tracker.download(context, description, origin, options);
  };

  const search = (
    description: EventTrackingDescription,
    options?: EventTrackingOptions,
  ): void => {
    window.omie.tracker.search(context, description, origin, options);
  };

  const loadPage = (
    setContext: EventTrackingContext,
    description: EventTrackingDescription,
    setOrigin?: EventTrackingClientOrigin,
    options?: EventTrackingOptions,
  ): void => {
    window.omie.tracker.loadPage(setContext, description, setOrigin || origin, options);
  };

  return {
    click,
    download,
    search,
    loadPage,
  };
};

export default useAnalytics;
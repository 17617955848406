import { getStage, isLocalhost } from 'utils/stage';

export const STORAGE_VERBOSE = 'verbose';

export interface ILoggerService {
  verbose: boolean;
  custom(keyMessage: string, message: any, ...rest: any[]): void;
  info(message: any, ...rest: any[]): void;
  log(message: any, ...rest: any[]): void;
  debug(message: any, ...rest: any[]): void;
  warning(message: any, ...rest: any[]): void;
  error(message: any, ...rest: any[]): void;
  tracking(message: any, ...rest: any[]): void;
  divisor(): void;
  setVerbose(verbose: boolean): boolean;
}

class LoggerService implements ILoggerService {
  verbose = false;

  constructor() {
    this.verbose = getStage() !== 'prod' || isLocalhost || localStorage.getItem(STORAGE_VERBOSE) === 'true';
  }

  custom(keyMessage: string, message: any, ...rest: any[]): void {
    if (this.verbose) console.info(`[${keyMessage}]`, message, ...rest);
  }

  info(message: any, ...rest: any[]): void {
    if (this.verbose) console.info('[INFO]', message, ...rest);
  }

  log(message: any, ...rest: any[]): void {
    if (this.verbose) console.info('[INFO]', message, ...rest);
  }

  debug(message: any, ...rest: any[]): void {
    if (this.verbose) console.info('[DEBUG]', message, ...rest);
  }

  warning(message: any, ...rest: any[]): void {
    if (this.verbose) console.warn('[WARNING]', message, ...rest);
  }

  error(message: any, ...rest: any[]): void {
    console.error('[ERROR]', message, ...rest);
  }

  tracking(message: any, ...rest: any[]): void {
    console.info('[TRACKING]', message, ...rest);
  }

  divisor(): void {
    if (this.verbose) console.debug('----------------------------------------');
  }

  setVerbose(verbose: boolean): boolean {
    this.verbose = verbose;
    if (verbose) {
      this.info('Verbose mode enabled');
      localStorage.setItem(STORAGE_VERBOSE, 'true');
    } else {
      console.info('[INFO] Verbose mode disabled');
      localStorage.removeItem(STORAGE_VERBOSE);
    }
    return verbose;
  }

}

const Logger = new LoggerService();
window.omie = window.omie || {};
window.omie.logger = Logger;

export { Logger };
import { makeStyles } from '@mui/styles';
import { boxShadowDefault, headerHeight } from 'store/constant';
import { IThemeCustom } from 'themes';

const useStylesBase = makeStyles((theme: IThemeCustom) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    height: `${headerHeight}px`,
  },
  headerContainer: {
    padding: '24px !important',
    [theme.breakpoints.only('xs')]: {
      padding: '10px !important',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '14px !important',
    },
    [theme.breakpoints.only('md')]: {
      padding: '18px !important',
    },
  },
  appBar: {
    transition: theme.transitions.create('width'),
    backgroundColor: theme.palette.primary[800],
    minHeight: `${headerHeight}px`,
  },
  content: {
    width: '100vw',
    maxWidth: '100%',
    flexGrow: 1,
    padding: '36px 20px 20px 20px',
    marginTop: `${headerHeight}px`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.only('xs')]: {
      padding: '0px',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '16px 16px 16px 16px',
    },
    [theme.breakpoints.only('md')]: {
      padding: '22px 18px 18px 18px',
    },
    [theme.breakpoints.only('lg')]: {
      padding: '30px 18px 18px 18px',
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: '0px',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '0px',
    },
  },
  contentContainer: {
    padding: '24px !important',
    [theme.breakpoints.only('xs')]: {
      padding: '20px 10px 10px 10px !important',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '22px 14px 14px 14px !important',
    },
    [theme.breakpoints.only('md')]: {
      padding: '22px 18px 18px 18px !important',
    },
  },
  logoSize: {
    width: '82px',
    height: 'auto',
    cursor: 'pointer',
    objectFit: 'cover',
  },
  link: {
    color: theme.palette.primary['800'],
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
  cardBase: {
    borderRadius: '5px',
    borderTop: `3px solid ${theme.palette.primary[800]}`,
    boxShadow: boxShadowDefault,
    backgroundColor: '#fff',
  },
  pageTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#2a2a2a',
    marginBottom: '15px',
  },
  alertOmieLink: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default useStylesBase;
/* eslint-disable max-len */
import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import { emailAjuda } from 'store/constant';
import './styles/PageError.scss';
import { stage } from 'services';

interface PageErrorProps {
  error?: Error | null;
  errorInfo?: React.ErrorInfo | null;
  messageError?: string | null;
  retryFunction?: () => void;
}

const ErrorMessage = (props: { messageError?: PageErrorProps['messageError'] }): React.ReactElement => {
  return (
    <div className='error-message'>
      {
        (props.messageError)
          ? props.messageError
          : 'Eita... parece que algo deu errado :('
      }
    </div>
  );
};

const AnimationBrowser = (): React.ReactElement => {
  return (
    <div className="browser">
      <div className="controls">
        <i></i>
        <i></i>
        <i></i>
      </div>
      <div className="eye"></div>
      <div className="eye"></div>
      <div className="mouth">
        <div className="lips"></div>
        <div className="lips"></div>
        <div className="lips"></div>
        <div className="lips"></div>
        <div className="lips"></div>
        <div className="lips"></div>
        <div className="lips"></div>
        <div className="lips"></div>
      </div>
    </div>
  );
};

const RetryButton = (props: { onClick?: PageErrorProps['retryFunction'] }): React.ReactElement | null => {

  if (!props.onClick) return null;

  return (
    <Button variant="text"
      onClick={props.onClick}
      startIcon={<FontAwesomeIcon iconName={'redo'} color={'inherit'} />}
      sx={{
        color: (theme) => theme.palette.primary[800],
        backgroundColor: 'transparent',
        textTransform: 'none',
        margin: '15px 0 0 0',
        '&:hover': {
          color: (theme) => theme.palette.secondary.dark,
          backgroundColor: 'transparent',
        },
      }}
    >
      Tentar novamente
    </Button>
  );
};

const SupportMessage = (): React.ReactElement => {

  const handleClick = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    window.omie.tracker.click('page_error_action', 'email');
    window.open(`mailto:${emailAjuda}`)?.focus();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const TypographyMessage = ({ children }: { children: any }): React.ReactElement => {
    return (
      <Typography variant="h6" color="textSecondary" gutterBottom
        sx={{ textAlign: 'center', padding: '0' }}
      >
        {children}
      </Typography>
    );
  };

  return (
    <Grid container
      sx={{
        margin: '40px 0 0 0',
      }}
    >
      <Grid item xs={12}>
        <TypographyMessage>
          Não se preocupe que já estamos sabendo. Por favor, tente novamente.
        </TypographyMessage>
        <TypographyMessage>
          Se preferir você pode também <strong onClick={handleClick} style={{ cursor: 'pointer' }}>entrar em contato com a gente por aqui</strong>.
        </TypographyMessage>
      </Grid>
    </Grid>
  );
};

const PageError = (props: PageErrorProps): React.ReactElement => {

  const { error, errorInfo, messageError, retryFunction } = props;

  return (
    <Grid item xs={12}>
      <div className="container-error">
        <ErrorMessage messageError={messageError} />
        <AnimationBrowser />
        <RetryButton onClick={retryFunction} />
        {
          (stage !== 'prod')
            ? (
              <details style={{ whiteSpace: 'pre-wrap' }}>
                {error && error.toString()}
                <br />
                {errorInfo && errorInfo.componentStack}
              </details>
            )
            : <SupportMessage />
        }
      </div>
    </Grid>
  );
};

export default PageError;
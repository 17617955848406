import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DashboardState {
  isLoading: boolean;
  isVisibleBannerComercial: boolean;
}

export const initialState: DashboardState = {
  isLoading: false,
  isVisibleBannerComercial: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    setLoadingDashboard: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsVisibleBannerComercial: (state, action: PayloadAction<boolean>) => {
      state.isVisibleBannerComercial = action.payload;
    },
  },
});

export const { setLoadingDashboard, setIsVisibleBannerComercial } = dashboardSlice.actions;
export default dashboardSlice.reducer;

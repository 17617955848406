import { Buffer } from 'buffer';
import { Logger } from 'services';

export const floatToString = (value, decimals = 2) => {
  if (value) {
    if (typeof value !== 'string') {
      try {
        value = parseFloat(value)
          .toFixed(decimals)
          .replace('.', ',')
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
      } catch (error) {
        value = '0,00';
      }
    }
  } else {
    value = '0,00';
  }
  return value;
};

export const stringToFloat = (value) => {
  if (typeof value === 'string') {
    value = value.replaceAll('R$ ', '');
    value = value.replaceAll(' %', '');
    value = value.replaceAll('.', '');
    value = value.replace(',', '.');
  }
  if (/^([-+])?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) return Number(value);
  return NaN;
};

export const stringToDate = (dateString) => {
  if (dateString === undefined || dateString === null) return null;

  try {
    const dateFormatted = new Date(`${dateString}T00:00:00+00:00`);
    if (dateFormatted.toString() === 'Invalid Date') {
      Logger.error('Invalid Date');
      return null;
    }
    if (dateFormatted.toString() === 'NaN') {
      Logger.error('Invalid Date: NaN');
      return null;
    }
    return dateFormatted;
  } catch (error) {
    Logger.error(error);
    return null;
  }
};

export const daysDiff = (date1, date2) => {
  if (date1 === null || date2 === null) return null;

  const diff = Math.abs(date1 - date2);
  const result = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return result;
};

export const formatDate = (date) => {
  if (date === null) return '';
  const splitDate = date.split('-');
  const dateFormatted = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
  return dateFormatted;
};

export const formatFloatToRealCurrency = (value) => {
  return `R$ ${floatToString(value, 2)}`;
};

export const formatFloatToRealCurrencyTextMi = (value) => {

  const valor = `R$ ${floatToString(value, 2)}`;
  const separate = valor.split('.');

  // R$ 100,00       - 1 length
  // R$ 1.000,00     - 2 length
  // R$ 10.000,00    - 2 length
  // R$ 100.000,00   - 2 length
  // R$ 1.000.000,00 - 3 length

  if (separate.length === 1) {
    return valor;
  }
  else if (separate.length === 2) {
    // valida se é 10 mil ou mais
    if (separate[0].replace('R$ ', '').length > 1) {
      return `${separate[0]} mil`;
    }
    else {
      return valor;
    }    
  }
  else if (separate.length === 3) {
    return `${separate[0]}.${separate[1][0]} mi`;
  }
  else if (separate.length > 3) {
    return `${separate[0]}.${separate[1][0]} bi`;
  }
  else {
    return valor;
  }

};

export const maskNumber = (value, _validX = false) => {

  if (_validX) {
    if (value.toLowerCase().includes('x')) {
      return value;
    } else {
      return value.replace(/\D/g, '');
    }
  } else {
    return value.replace(/\D/g, '');
  }

};

export const monthDiff = (d1, d2) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

export const stringToBase64 = (value) => {
  return Buffer.from(value).toString('base64');
};

export const base64ToString = (value) => {
  return Buffer.from(value, 'base64').toString();
};

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) return null;

  return `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`;

};

export const zeroToLeft = (value, length) => {
  if (value.toString().length >= length) return value;
  return value.toString().padStart(length, '0');
};
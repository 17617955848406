import axios from 'axios';
import { isLocalhost } from 'utils/stage';
import { urlCors } from 'services/api/constants';

const apiExternal = axios.create();

apiExternal.interceptors.request.use(config => {
  if (isLocalhost && config.url && !config.url.includes(urlCors)) {
    config.url = `${urlCors}/${config.url}`;
  }

  return config;
}, error => {
  Promise.reject(error);
});

export { apiExternal };
import { AxiosResponse } from 'axios';
import { PostNewAddress } from 'interfaces/api';
import { UserAddress } from 'interfaces/user';
import { apiOmie } from 'services/api/omie';

export const getMyAddresses = async (): Promise<AxiosResponse<UserAddress[]>> => {
  return apiOmie.get('/users/me/address/');
};

export const postNewAddress = async (user: UserAddress): Promise<AxiosResponse<PostNewAddress>> => {
  const url = '/users/me/address/';
  return apiOmie.post(url, user);
};

export const updateChangeAddress = async (user: UserAddress): Promise<AxiosResponse<PostNewAddress>> => {

  if (!user.address_id) {
    throw new Error('Address ID not found');    
  }

  const url = `/users/me/address/${user.address_id}`;
  return apiOmie.patch(url, user);
};
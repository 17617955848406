import { createContext } from 'react';
import { IUserAuth, IUserJwt } from 'services';

export interface IProviderAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: IUserAuth | null;
  logoutUser: () => void;
  getNewToken: (token: IUserJwt['token'], refreshToken: IUserJwt['refresh_token']) => Promise<void>;
}

const defaultContext: IProviderAuth = {
  isLoading: true,
  isAuthenticated: false,
  user: null,
  logoutUser: () => {},
  getNewToken: async () => {},
};

export const UserContext = createContext<IProviderAuth>(defaultContext);
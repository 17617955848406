import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { AppBar, CssBaseline, Toolbar, Container, Grid } from '@mui/material';
import Header from 'layout/Header';
import Sidebar from 'layout/Sidebar';
import useStyles from 'themes/baseStyles';
import Toast from 'ui-component/Toast';
import { setLeftNav } from 'store/reducer/customization';
import ErrorBoundary from 'components/ErrorBoundaries';
// import CallOmie from 'components/CallOmie';
import useWindowSize from 'hooks/useWindowSize';
import Footer from './Footer';
import { headerHeight, footerHeight } from 'store/constant';
import TopBanner from './Header/TopBanner/index';
import TopBannerHome from './Header/TopBannerHome';

const BaseLayout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);

  const handleLeftDrawerToggle = () => {
    dispatch(setLeftNav(!leftDrawerOpened));
  };

  const isTrial = useSelector((state) => state.myapps.isTrial);
  const appTrial = useSelector((state) => state.myapps.appTrial);
  const isTrainingOnline = useSelector((state) => state.myapps.isTrainingOnline);
  const isVisibleBannerComercial = useSelector((state) => state.dashboard.isVisibleBannerComercial);

  const isBannerVisible = () => {
    return (isTrial || !isTrial && isTrainingOnline || isVisibleBannerComercial) ? true : false;
  };

  return (
    <>
      <Toast />
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position={'fixed'}
        color="inherit"
        elevation={0}
        className={classes.appBar}
      >
        <TopBanner isTrial={isTrial} app={appTrial} isTrainingOnline={isTrainingOnline} />
        <TopBannerHome isVisibleBannerComercial={isVisibleBannerComercial} />
        <Toolbar className={classes.toolbar}>
          <Container className={classes.headerContainer}>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
          </Container>
        </Toolbar>
      </AppBar>

      <Sidebar
        drawerOpen={windowSize.isDown('md') ? leftDrawerOpened : false}
        drawerToggle={handleLeftDrawerToggle}
      />

      <Grid container component={'main'} className={classes.content}
        // alignItems="flex-start"
        // alignContent={'flex-start'}
        sx={{
          minHeight: `calc(100vh - ${headerHeight + footerHeight}px)`,
          marginTop: isBannerVisible() ? '120px!important' : '68px',
        }}
      >
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Grid>

      <Grid container component={'footer'} className={classes.content}
        justifyContent={'center'}
        sx={{
          minHeight: footerHeight,
          margin: '0 !important',
          padding: '8px 0 0 0 !important',
        }}
      >
        <Footer />
      </Grid>


    </>
  );
};

export default BaseLayout;

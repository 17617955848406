import axios from 'axios';
import { getNewToken } from 'hooks/useAuth';
import { LocalStorageService, Logger, stage } from 'services';
import { isLocalhost } from 'utils/stage';
import { urlCors } from 'services/api/constants';
import { IApiConfig } from './interfaces';

const configs: IApiConfig = {
  prod: {
    baseURL: 'https://app.omie.com.br/api/portal',
  },
  release: {
    baseURL: 'https://appnext.omie.com.br/api/portal',
  },  
  dsv: {
    baseURL: 'https://appdsv.omie.com.br/api/portal',
  },
};
const configApi = configs[stage];

const apiOmie = axios.create({
  baseURL: configApi.baseURL, //'https://appdsv.omie.com.br/api/portal'
});

apiOmie.interceptors.request.use(config => {

  if (isLocalhost && config.url && !config.url.includes(urlCors)) {
    config.url = `${urlCors}/${config.baseURL}${config.url}`;
  }

  const token = LocalStorageService.getAccessToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, error => {
  Promise.reject(error);
});

apiOmie.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    Logger.error(error);
    if (!error.response) {
      try {
        Logger.error(error.toJSON());
      } catch (errorCatch) {
        Logger.error(error);
        Logger.error(errorCatch);
      }
      return Promise.reject({
        'data': {
          'status': 'gateway_timeout',
          'message': 'Não foi possível carregar os dados. Tente novamente mais tarde.',
        },
      });
    }

    const originalRequest = error.config;
    const status = error.response.status;

    if (status === 502) {
      try {
        Logger.error(error.toJSON());
      } catch (errorCatch) {
        Logger.error(error);
        Logger.error(errorCatch);
      }
      return Promise.reject({
        'data': {
          'status': 'bad_gateway',
          'message': 'Não foi possível carregar os dados. Tente novamente mais tarde.',
        },
      });
    }

    if (
      (status === 401 || status === 403) && !originalRequest._retry) {
      Logger.error(`Status: ${status} - ${error.response.data.message}`);

      if (originalRequest.url.includes('refresh-token')) {
        return Promise.reject(error);
      }

      originalRequest._retry = true;

      const accessToken = LocalStorageService.getAccessToken();
      const refreshToken = LocalStorageService.getRefreshToken();

      if (accessToken && refreshToken) {
        console.error('Tentando refreshar o token');
        await getNewToken(accessToken, refreshToken, true);
        return apiOmie(originalRequest);
      }
    }

    // return Error object with Promise
    return Promise.reject(error);
  },
);

export { apiOmie };
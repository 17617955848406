import { Theme } from '@mui/material';
import AlertConsultaRestricao from 'layout/Header/Logo/AlertConsultaRestricao';
import { makeStyles } from '@mui/styles';
import { boxShadowDefault } from 'store/constant';

export const useStylesProvider = makeStyles((theme: Theme) => ({
  container: {
    boxShadow: boxShadowDefault,
    width: '100vw',
    padding: '8px 16px',
    [theme.breakpoints.only('lg')]: {
      padding: '12px 30px',
    },
    [theme.breakpoints.only('xl')]: {
      padding: '14px 0',
    },
  },
}));

export default function TooltipConsultaRestricao(): React.ReactElement | null {

  const classesProvider = useStylesProvider();

  const handleClose = (): void => {

  };

  return (
    <div className={classesProvider.container}>
      <div style={{ marginTop: '-10px !important' }}>
        <AlertConsultaRestricao handleClose={handleClose} showClose={false} />
      </div>
    </div>
  );

}
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import { Avatar, Chip, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { setLeftNav, setMenuOpen } from 'store/reducer/customization';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import { useCustomizationDispatch } from 'hooks/useCustomization';
import { stageOmie } from 'services';
import useAuth from 'hooks/useAuth';
import ModalLogin from 'components/ModalLogin/index';
import useAnalytics from 'hooks/useAnalytics';

// style constant
const useStyles = makeStyles((theme) => ({
  listIcon: {
    minWidth: '18px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  listCustomIconSub: {
    width: '6px',
    height: '6px',
  },
  listCustomIconSubActive: {
    width: '8px',
    height: '8px',
  },
  menuIcon: {
    color: theme.palette.secondary.main,
    minWidth: '25px',
  },
  listItem: {
    marginBottom: '5px',
    alignItems: 'center',
    backgroundColor: theme.palette.primary[800],
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: `${theme.palette.primary[800]} !important`,
      fontWeight: '800 !important',
      '& svg': {
        color: `${theme.palette.primary[800]} !important`,
        fontWeight: '800 !important',
      },
    },
  },
  listItemNoBack: {
    marginBottom: '5px',
    backgroundColor: 'transparent !important',
    paddingTop: '8px',
    paddingBottom: '8px',
    alignItems: 'flex-start',
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },
}));

const NavItem = ({ item, level }) => {
  const classes = useStyles();
  const dispatch = useCustomizationDispatch();
  const customization = useSelector((state) => state.customization);
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
  const auth = useAuth();

  const [needLogin, setNeedLogin] = React.useState();
  const [href, setHref] = React.useState();

  const analytics = useAnalytics('header_button', 'portal');

  // active menu item on page load
  React.useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch(setMenuOpen(item.id));
    }
    // eslint-disable-next-line
  }, []);  

  if (!auth.isAuthenticated) {
    if (item.onlyAuthenticated !== false) return null;
  }

  const itemIcon = item.icon
    ? (
      <FontAwesomeIcon iconName={item.icon} color={'inherit'} fixedWidth />
    )
    : (
      <FiberManualRecordIcon
        className={
          customization.isOpen.findIndex((id) => id === item.id) > -1 ? classes.listCustomIconSubActive : classes.listCustomIconSub
        }
        fontSize={level > 0 ? 'inherit' : 'default'}
      />
    );

  let itemIconClass = !item.icon ? classes.listIcon : classes.menuIcon;
  itemIconClass = customization.navType === 'nav-dark' ? [itemIconClass, classes.listCustomIcon].join(' ') : itemIconClass;

  const itemTarget = (item.target) ? '_blank' : '';

  let listItemProps = { component: React.forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} />) };
  if (item.external) {
    listItemProps = { component: 'a', href: item.url };
  }

  const itemHandler = (event) => {

    analytics.click(`header_button_${item.analytics}`);

    if (!auth.isAuthenticated && item.requiredLogin) {
      event.preventDefault();
      setHref(`${stageOmie}${item.url}`);
      setNeedLogin(true);
      return;
    }
    else {
      setHref(undefined);
    }

    if (item.external === true) {
      event.preventDefault();
      analytics.click(`open_${item.analytics}`);
      window.open(item.url, '_blank').focus();
      return;
    }

    if (matchDownMd) dispatch(setLeftNav(false));
  };

  const handleCloseModalLogin = () => {
    setNeedLogin(false);
  };

  return (
    <>
      {
        needLogin && (
          <ModalLogin href={href} open={true} message={'Visualize rapidamente seu histórico financeiro, notas fiscais emitidas, 2ª via de boletos e muito mais!'} handleClose={handleCloseModalLogin} />
        )
      }
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        className={level > 1 ? classes.listItemNoBack : classes.listItem}
        sx={{ borderRadius: `${customization.borderRadius}px` }}
        selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
        onClick={itemHandler}
        target={itemTarget}
        style={{ paddingLeft: `${level * 23}px` }}
      >
        <ListItemIcon className={itemIconClass} >
          {itemIcon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant={'body1'}
              sx={{
                color: 'inherit',
                fontWeight: 'inherit',
              }}
            >
              {item.title}
            </Typography>
          }
          secondary={
            item.caption && (
              <Typography variant="caption" className={classes.subMenuCaption} display="block" gutterBottom>
                {item.caption}
              </Typography>
            )
          }
        />
        {item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>
    </>
    
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;

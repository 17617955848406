import { makeStyles } from '@mui/styles';
import {
  Box, Divider, Drawer,
  Grid, Theme,
} from '@mui/material';
import MenuList from './MenuList';
import Logo from 'ui-component/Logo';
import OmieCard from 'components/CardAdvertising/OmieCard';
import { boxShadowDefault, drawerWidth, headerHeight } from 'store/constant';
import MenuUser from './MenuUser';
import useAuth from 'hooks/useAuth';
import MenuLogin from './MenuLogin';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    height: 'calc(100vh)',
    width: drawerWidth,
    boxShadow: boxShadowDefault,
    background: theme.palette.primary[800],
    color: theme.palette.text.primary,
    borderRight: 'none',
    [theme.breakpoints.up('lg')]: {
      top: `${headerHeight}px`,
    },
  },
  boxContainer: {
    padding: '32px 8px 8px 8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  buttonLogout: {
    width: '36px',
    height: '36px',
  },
}));

interface SidebarProps {
  drawerOpen: boolean,
  drawerToggle: () => void,
}

const Sidebar = (props: SidebarProps): JSX.Element | null => {

  const { drawerOpen, drawerToggle } = props;
  // const windowSize = useWindowSize();
  const classes = useStyles();
  const auth = useAuth();

  // if (windowSize.isUp('md')) return null;

  return (
    <Drawer
      container={undefined}
      variant={'temporary'}
      anchor="left"
      open={drawerOpen}
      onClose={drawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
    >

      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        sx={{ height: '100%' }}
      >
        <Grid item container>
          <Grid item xs={12}>
            <Box sx={{ display: { xs: 'block' } }}>
              <div className={classes.boxContainer}>
                <Logo aspect={1.5} />
              </div>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <OmieCard />
          </Grid>
        </Grid>

        <Divider />

        <Grid item sx={{ padding: '0 8px' }}>
          <MenuList />
        </Grid>

        <div style={{ flex: '1' }}></div>

        <Divider />

        {
          (auth.isAuthenticated)
            ? <MenuUser />
            : <MenuLogin />
        }
      </Grid>
    </Drawer>
  );
};

export default Sidebar;

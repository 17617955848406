import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NotificationState {
  notifications: number,
  isLoading: boolean,
}

export const initialState: NotificationState = {
  notifications: 0,
  isLoading: false,
};

export const notification = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    setNotificationCredit: (state, action: PayloadAction<number>) => {
      state.notifications = action.payload;
    },
    setLoadingNotification: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setNotificationCredit,
  setLoadingNotification,
} = notification.actions;
export default notification.reducer;
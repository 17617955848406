import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './assets/scss/style.scss';
import { ProviderWindowSize } from 'hooks/useWindowSize';
import VersionControl from 'components/VersionControl';

const portalClient = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

portalClient.render(
  <ProviderWindowSize>
    <VersionControl />
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ProviderWindowSize>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


import React, { ChangeEvent } from 'react';
import {
  Grid,
  Typography,
  Box,
  Dialog,
  Button,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import useStyles from './styles';
import MainCard from 'ui-component/cards/MainCard';
import { toast } from 'react-toastify';

import { maskCnpj, isCnpjValid } from 'utils/cnpj-utils';

import useAnalytics from 'hooks/useAnalytics';
import { postPortalContact } from 'resources';
import { Logger } from 'services';

import LoadingSpinner from 'components/LoadingSpinner';
import { FormularioContato } from 'interfaces';
import { maskCellphone } from 'utils/masks-utils';
import { isEmailValid } from 'utils/email-validate';
import useAuth from 'hooks/useAuth';

interface ModalContatoProps {
  open: boolean;
  handleClose: () => void;
}

export default function ModalContato(props: ModalContatoProps): JSX.Element {
  const { open, handleClose } = props;

  const analytics = useAnalytics('modal_contact', 'portal');
  const auth = useAuth();

  const [isLoading, setIsLoading] = React.useState(false);

  const [nome, setNome] = React.useState(auth.user?.getFullName() || '');
  const [email, setEmail] = React.useState(auth.user?.email || '');
  const [whatsapp, setWhatsapp] = React.useState('');
  const [cnpj, setCnpj] = React.useState<string>('');
  const [faturamento, setFaturamento] = React.useState('');
  const [leuTermos, setLeuTermos] = React.useState(false);

  const classes = useStyles();

  const clickFechar = async (): Promise<void> => {
    handleClose();
  };

  const handleChangeFaturamento = (event: SelectChangeEvent): void => {
    setFaturamento(event.target.value as string);
  };

  function changeCheckTermos(event: ChangeEvent<HTMLInputElement>, checked: boolean): void {
    setLeuTermos(checked);
  }

  const clickTermosUso = async (): Promise<void> => {
    analytics.click('click_see_terms');
    const url = 'https://www.omie.com.br/seguranca-e-privacidade/politica-de-privacidade-do-site.pdf';
    window.open(url, '_blank')?.focus();
  };

  const clearFields = async (): Promise<void> => {
    setNome('');
    setEmail('');
    setWhatsapp('');
    setCnpj('');
    setFaturamento('');
    setLeuTermos(false);
  };

  const clickEnviar = async (): Promise<void> => {
    // valida os campos
    if (nome.trim().length <= 3) {
      toast.error('Informe o nome corretamente, por favor.');
      return;
    }

    const isValidEmail = isEmailValid(email);

    if (email.trim().length <= 0 || !isValidEmail) {
      toast.error('Informe um e-mail válido, por favor.');
      return;
    }

    if (email.indexOf('@omie') >= 0) {
      toast.error('Emails @omie não são permitidos.');
      return;
    }

    if (whatsapp.trim().length < 14) {
      toast.error('Informe um WhatsApp válido, por favor.');
      return;
    }

    if (!isCnpjValid(cnpj)) {
      toast.error('Informe um CNPJ válido, por favor.');
      return;
    }

    if (faturamento.trim().length <= 0) {
      toast.error('Selecione o faturamento, por favor.');
      return;
    }

    if (!leuTermos) {
      toast.error("É necessário concordar com os 'termos de uso' para continuar.");
      return;
    }

    analytics.click('click_send');
    setIsLoading(true);

    const dados = {
      documento: cnpj,
      email: email,
      faturamento: faturamento,
      nome: nome,
      whatsapp: whatsapp,
    } as FormularioContato;

    const response = await postPortalContact(dados);

    Logger.info('response', response);

    const { redirectUri, errors } = response;

    if (redirectUri !== undefined && redirectUri !== null) {
      clearFields();
      handleClose();
      toast.success('Sucesso! Um de nossos consultores entrará em contato com você em breve.');
    } else if (errors !== undefined && errors !== null) {
      Logger.error(errors);
      toast.error('Ocorreu um erro. Tente novamente.');
    } else {
      Logger.error('Erro desconhecido');
      toast.error('Ocorreu um erro. Tente novamente.');
    }

    setIsLoading(false);
  };

  function textFieldChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
    if (event.target.id === 'nome') {
      setNome(event.target.value);
    } else if (event.target.id === 'email') {
      setEmail(event.target.value);
    } else if (event.target.id === 'whatsapp') {
      const phoneMask = maskCellphone(event.target.value);
      setWhatsapp(phoneMask);
    } else if (event.target.id === 'cnpj') {
      const cnpjFormat = maskCnpj(event.target.value);
      setCnpj(cnpjFormat);
    }
  }

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
          maxWidth: '900px',
          display: 'block',
        },
      }}
    >
      <MainCard className={classes.card} contentClass={classes.content} sx={{ borderTop: '0' }}>
        <Grid container className={classes.modalHeader}>
          <Grid item xs={12}>
            <Button className={classes.buttonBack} onClick={clickFechar}>
              <i className="fa fa-times"></i>
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.modalBody}>
          <Grid item xs={12} mt={0}>
            <Typography className={classes.titleBody}>Quer saber mais como destravar seu crescimento?</Typography>

            <Box className={classes.containerSubText}>
              <Typography className={classes.text}>
                Receba o contato de nossos especialistas e conheça seu próximo sistema de gestão
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} mb={0}>
            <Grid container columnSpacing={3}>
              <Grid item xs={12} mb={1}>
                <TextField
                  sx={{ width: '100%' }}
                  id="nome"
                  value={nome}
                  onChange={textFieldChange}
                  size="small"
                  placeholder="Nome*"
                />
              </Grid>

              <Grid item xs={12} mb={1}>
                <TextField
                  sx={{ width: '100%' }}
                  id="email"
                  placeholder="E-mail*"
                  value={email}
                  onChange={textFieldChange}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} mb={1}>
                <TextField
                  sx={{ width: '100%' }}
                  id="whatsapp"
                  placeholder="Número de WhatsApp (com DDD)*"
                  value={whatsapp}
                  onChange={textFieldChange}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} mb={1}>
                <TextField
                  sx={{ width: '100%' }}
                  id="cnpj"
                  placeholder="CNPJ*"
                  inputProps={{
                    maxLength: 18,
                  }}
                  value={cnpj}
                  onChange={textFieldChange}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} mb={1}>
                <FormControl fullWidth size="small">
                  <InputLabel id="select-faturamento">Faturamento*</InputLabel>
                  <Select
                    labelId="select-faturamento"
                    id="select-faturamento-input"
                    value={faturamento}
                    placeholder="Faturamento*"
                    onChange={handleChangeFaturamento}
                  >
                    <MenuItem value={'81000'}>Até R$81.000,00</MenuItem>
                    <MenuItem value={'180000'}>Até R$180.000,00</MenuItem>
                    <MenuItem value={'360000'}>Até R$360.000,00</MenuItem>
                    <MenuItem value={'720000'}>Até R$720.000,00</MenuItem>
                    <MenuItem value={'1800000'}>Até R$1.800.000,00</MenuItem>
                    <MenuItem value={'4800000'}>A partir de R$1.800.000,00</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} mb={1}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked={false} onChange={changeCheckTermos} />}
                    label={
                      <Typography sx={{ color: '#FFF' }}>
                        <b>Concordo com os</b>{' '}
                        <a onClick={clickTermosUso}>
                          <u>termos de uso</u>
                        </a>
                        *
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} mb={0}>
                <Typography className={classes.text}>(*) Campo obrigatório</Typography>
                {isLoading ? (
                  <Box sx={{ width: '100%', marginTop: '15px' }}>
                    <LoadingSpinner size={30} />
                  </Box>
                ) : (
                  <Button className={classes.buttonEnviar} onClick={clickEnviar}>
                    <b>Enviar</b>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </Dialog>
  );
}

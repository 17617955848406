import { useState, useEffect, useContext, useMemo } from 'react';
import { WindowSizeContext } from 'context/WindowSizeContext';
import { useTheme } from '@mui/material/styles';
import { Logger } from 'services';

export function widthToBreakpoint(width, breakpoints) {
  const keys = [...Object.keys(breakpoints)].reverse();
  let breakpoint = 'xs';
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (width >= breakpoints[key]) {
      breakpoint = key;
      break;
    }
  }
  return breakpoint;
}

function useProvideWindowSize() {

  const theme = useTheme();
  const breakpoints = { ...theme.breakpoints.values };
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    currentBreakpoint: widthToBreakpoint(window.innerWidth, breakpoints),
  });

  const isDown = (breakpointValue) => {

    const { currentBreakpoint } = windowSize;

    if (breakpointValue === 'xs' || breakpointValue === 'sm') {
      return ['xs'].includes(currentBreakpoint);
    }
    if (breakpointValue === 'md') {
      return ['xs', 'sm'].includes(currentBreakpoint);
    }
    if (breakpointValue === 'lg') {
      return ['xs', 'sm', 'md'].includes(currentBreakpoint);
    }
    return ['xs', 'sm', 'md', 'lg'].includes(currentBreakpoint);
  };

  const isUp = (breakpointValue) => {

    const { currentBreakpoint } = windowSize;

    if (breakpointValue === 'xs') {
      return true;
    }
    if (breakpointValue === 'sm') {
      return !['xs'].includes(currentBreakpoint);
    }
    if (breakpointValue === 'md') {
      return !['xs', 'sm'].includes(currentBreakpoint);
    }
    if (breakpointValue === 'lg') {
      return !['xs', 'sm', 'md'].includes(currentBreakpoint);
    }
    return true;
  };

  useEffect(() => {
    function handleResize() {
      const newBreakpoint = widthToBreakpoint(window.innerWidth, breakpoints);
      if (windowSize.currentBreakpoint !== newBreakpoint) {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
          currentBreakpoint: newBreakpoint,
        });
      }
    }
    window.addEventListener('resize', handleResize, { passive: true });
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.currentBreakpoint]);

  return { ...windowSize, isDown, isUp };
}

export function ProviderWindowSize({ children }) {

  const window = useProvideWindowSize();

  const sizeMemoized = useMemo(() => ({
    ...window,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [window.currentBreakpoint]);

  Logger.debug(`Breakpoint atual: ${window.currentBreakpoint}`);

  return (
    <WindowSizeContext.Provider value={sizeMemoized}>
      {children}
    </WindowSizeContext.Provider>
  );
}

const useWindowSize = () => useContext(WindowSizeContext);

export default useWindowSize;

import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import useStyles from 'themes/baseStyles';
import useAnalytics from 'hooks/useAnalytics';

export default function AlertOmie(): JSX.Element {

  const classes = useStyles();
  const analytics = useAnalytics('alert_adversiting', 'portal');

  const handleClick = (): void => {
    analytics.click('Omie');
    // eslint-disable-next-line max-len
    const url = 'https://www.omie.com.br/portal/?utm_campaign=portal-plg&utm_source=bt-conheca&utm_medium=propagando-omie';
    window.open(url, '_blank')?.focus();
  };

  return (
    <Container sx={{ height: '100%' }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant={'body1'}>
            Sistema de gestão direto ao ponto, pra resolver seu dia.
            Do tamanho que sua empresa precisa.{' '}
            <strong className={classes.alertOmieLink} onClick={handleClick}>
              Clique aqui para saber mais{' '}
              <i className="fa-sharp fa-solid fa-arrow-up-right-from-square"></i>
            </strong>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
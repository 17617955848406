import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: '500px',
    width: '500px',
    background: '#001e27',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
    },
    [theme.breakpoints.down('xl')]: {
      maxWidth: '900px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '500px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'auto',
    },
    '& .MuiFormControl-root label' : {
      color: '#46595f',
      position: 'absolute',
      left: '-13px',
      top: '-3px',
      fontWeight: '600',
    },
    '& .MuiFormControl-root .MuiInputLabel-shrink': {
      display: 'none!important',
    },
    '& .MuiOutlinedInput-input': {
      background: 'transparent',
      border: 'none',
      borderBottom: '2px solid #99a5a9',
      borderRadius: '0px',
      fontSize: '1rem',
      padding: '4px 0',      
      transition: '.2s',
      width: '100%',
      outline: 'none',
      color: '#fff',
      backgroundColor: '#001e27',
    },
    '& .MuiOutlinedInput-root': {
      background: 'transparent',

      '& input': {
        border: 'none',
        borderBottom: '2px solid #99a5a9',
        borderRadius: '0px',
        fontSize: '1rem',
        padding: '4px 0',
        background: 'rgba(0, 0, 0, 0)',
        transition: '.2s',
        width: '100%',
        outline: 'none',
        color: '#fff',
        backgroundColor: '#001e27',

        '&:focus': {
          borderBottom: '2px solid #00E3F5',
        },
      },

      '& fieldset': {
        display: 'none!important',
      },
    },
  },
  content: {
    padding: '20px !important',
    '& *': {
      fontFamily: 'Poppins',
    },
  },
  modalHeader: {
    padding: '0px',
  },
  containerHeader: {
    display: 'flex',
  },
  containerHeaderText: {
    flex: '1',
  },
  iconTitle: {
    width: '48px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
    border: '1px solid #E4E7EC',
    borderRadius: '10px',
    color: '#0177FB',
    marginRight: '18px',
  },
  title: {
    color: '#101828',
    fontSize: '17px',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  subTitle: {
    color: '#000000',
    fontSize: '13px',
    fontWeight: '500',
    marginBottom: '0px',
  },
  modalBody: {
    padding: '0px',
  },
  text: {
    color: '#ddd',
    fontSize: '12px',
    fontWeight: '400',
  },
  buttonBack: {
    float: 'right',
    color: '#808f93',
    fontSize: '28px',
    fontWeight: '100',
    padding: '0px',
    background: 'transparent',

    '&:hover': {
      background: 'transparent',
      color: '#FFF',
    },
  },
  buttonEnviar: {
    fontSize: '14px',
    fontWeight: '600!important',
    textTransform: 'none',
    backgroundColor: '#00E3F5',
    color: '#001E27',
    borderRadius: '50px',
    padding: '8px 16px',
    lineHeight: '18px',
    border: '2px solid transparent',
    display: 'block',
    width: '100%',
    marginTop: '15px',

    '&:hover': {
      backgroundColor: '#00E3F5',
      color: '#001E27',
    },
  },
  textAmbiente: {
    fontSize: '13px',
    fontWeight: '500',
    float: 'right',
    color: '#001E27',
    marginTop: '10px',
  },
  titleBody: {
    color: '#FFF',
    fontWeight: '600',
    fontSize: '20px',
    marginTop: '5px',
    marginBottom: '10px',
  },
  containerSubText: {
    marginBottom: '10px',
  },
}));

export default useStyles;
import { Buffer } from 'buffer';
import { Logger } from 'services';

interface IJwtHeader {
  typ: string;
  alg: string;
}

interface IJwtPayload {
  exp: number,
  uid: string,
  uuid: string,
  email: string,
}

interface IJwtKeys {
  [key: string]: string | IJwtHeader | IJwtPayload;
}

export interface IJwt extends IJwtKeys {
  header: IJwtHeader,
  payload: IJwtPayload,
  signature: string,
}

export function parseJwt(jwtString: string): IJwt | null {
  try {
    const [header, payload, signature] = jwtString.split('.');
    const headerDecoded = Buffer.from(header, 'base64').toString();
    const payloadDecoded = Buffer.from(payload, 'base64').toString();

    const jwt: IJwt = {
      header: JSON.parse(headerDecoded),
      payload: JSON.parse(payloadDecoded),
      signature,
    };

    return jwt;
  } catch (error) {
    Logger.error(error);
    return null;
  }
}

export function jwtIsValid(jwtString: string): boolean {
  const jwt: IJwt | null = parseJwt(jwtString);
  if (!jwt) {
    Logger.error('Token inválido');
    return false;
  }

  const { payload } = jwt;
  const currentTime = new Date().getTime() / 1000;
  if (payload.exp < currentTime) {
    Logger.error('Token expirado');
    return false;
  }

  return true;
}

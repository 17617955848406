import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAnalytics from 'hooks/useAnalytics';
import { makeStyles } from '@mui/styles';
import { MyApps } from 'interfaces';

const useStyles = makeStyles(() => ({
  topBannerTrial: {
    padding: '7px 10px',
    textAlign: 'center',
    background: '#00E2F4',
    color: '#001E27',
    zIndex: 1,
    fontWeight: '400',
    fontFamily: 'Poppins',
    '& *': {
      fontFamily: 'Poppins',
    },
  },
  topBannerTrainingOnline: {
    padding: '4px 10px',
    textAlign: 'center',
    background: '#FFF',
    color: '#001E27',
    fontWeight: '400',
    fontFamily: 'Poppins',
    zIndex: 1,
    '& *': {
      fontFamily: 'Poppins',
    },
  },
  buttonTop: {
    color:' #001E27',
    border: '2px solid transparent',
    padding: '8px 14px',
    fontSize: '14px',
    fontWeight: '900 !important',
    lineHeight: '16px',
    borderRadius: '50px',
    textTransform: 'none',
    backgroundColor: '#ffffff',
    marginLeft: '20px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  }
}));

interface BannerAppTrialProps {
  app: MyApps
}

export function BannerAppTrial(props: BannerAppTrialProps): JSX.Element {
  const { app } = props;
  const classes = useStyles();
  const analytics = useAnalytics('banner_fixed_top_app_trial', 'my-apps');

  const getMessage = (date: MyApps['evaluation_date']): string => {
    if (date == null) return '';

    const dateSlitted = date.split('T')[0]; // "2022-10-03T13:57:04-0300"

    const date1 = new Date();
    const date2 = new Date(dateSlitted);

    const diasEmTempo = date2.getTime() - date1.getTime();
    const horasRestantes = (diasEmTempo / (1000 * 3600));

    if (horasRestantes <= 0) return 'o prazo de testes acabou';
    else if (horasRestantes <= 24) return 'último dia para conclusão do Teste Grátis';
    else if ((horasRestantes / 24) >= 99) return '+99 dias restantes para conclusão do Teste Grátis';
    else return `${(horasRestantes / 24).toFixed(0)} dias restantes para conclusão do Teste Grátis`;

  };

  const clickBuy = async (
    event: React.MouseEvent<HTMLButtonElement>,
    url: string,
  ): Promise<void> => {
    analytics.click('click_buy_app_trial_' + url);
    window.open(url, '_blank')?.focus();    
  };

  return (
    <>
      <b style={{ marginRight: '5px' }}>{app.company_nicename}</b>{' '}
      {getMessage(app.evaluation_date)}
      <Button
        className={classes.buttonTop}
        onClick={async (e): Promise<void> => clickBuy(e, app.purchase_url!)}
      >
        Comprar agora &nbsp;&nbsp;<i className="fa fa-arrow-right"></i>
      </Button>
    </>
  );
}

export function BannerTrainingOnline(): JSX.Element {
  const navigate = useNavigate();
  const analytics = useAnalytics('banner_fixed_top_trainings', 'my-apps');

  const clickOpenTreinamentos = async (): Promise<void> => {
    analytics.click('live_trainings');
    navigate('/treinamentos?tab=2');
  };

  return (
    <>
      <div>
        <i
          className="fa fa-signal-stream"
          style={{ fontSize: '20px', position: 'relative', top: '3px', color: '#70D34E' }}
        />
        <b style={{ marginRight: '10px', marginLeft: '10px' }}>Treinamentos ao vivo</b>
        Tire suas dúvidas com nossos especialistas.
        <Button
          sx={{ color: '#001E27', fontWeight: 'bold', marginLeft: '20px' }}
          onClick={clickOpenTreinamentos}
        >
          Acessar &nbsp;<i className="fa fa-arrow-right"></i>
        </Button>
      </div>
    </>
  );
}

interface isTrialProps {
  isTrial: boolean
  app: MyApps | undefined
  isTrainingOnline: boolean
}

function validateAppExpiredLastHours(app: MyApps, maxHoursExpiredDays: number): boolean {

  if (!app.evaluation_date) return false;
  if (app.active === true) return true;

  try {
    const currentTime = new Date();
    const evalutionDate = new Date(app.evaluation_date);
  
    const diff = currentTime.getTime() - evalutionDate.getTime();
    const diffHours = Math.ceil(diff / (1000 * 60 * 60)); 
  
    return diffHours <= maxHoursExpiredDays;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export default function TopBanner(props: isTrialProps): JSX.Element {
  const { isTrial, app, isTrainingOnline } = props;
  const classes = useStyles();

  return (
    <>
      {app != undefined && isTrial && validateAppExpiredLastHours(app, 48) ? (
        <Box className={classes.topBannerTrial}>
          <BannerAppTrial app={app} />
        </Box>
      ) : isTrainingOnline ? (
        <Box className={classes.topBannerTrainingOnline}>
          <BannerTrainingOnline />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

import { useState, useEffect } from 'react';
import { Logger, stage } from 'services';
import { hotjarContainer, hotjarEndpoint } from 'store/constant';
import useApp from './useApp';
import useAuth from './useAuth';

export type HotjarTokenProjectType = 'PORTAL' | 'MYAPPS';

const hotjarProjectPortalDsv = 3902316;
const hotjarProjectPortalProd = 3890956;
const hotjarProjectSiteProd = 1063729;

const getHotjarToken = (project: HotjarTokenProjectType): number => {
  if (project === 'PORTAL') {
    return ['prod'].includes(stage) ? hotjarProjectPortalProd : hotjarProjectPortalDsv;
  }
  // MYAPPSS
  return hotjarProjectSiteProd;
};

async function loadHotjar(
  callback: () => Promise<void>,
  onError: () => Promise<void>,
  project: HotjarTokenProjectType,
): Promise<void> {
  const scriptLoaded = document.getElementById(hotjarContainer);
  if (scriptLoaded) return;

  Logger.debug('[HOTJAR] Carregando hotjar scripts');

  const container = document.getElementsByTagName('head')[0];

  const script = document.createElement('script');

  const keyHotjar = getHotjarToken(project);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window.hj =
    window.hj ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (...args: any[]): void {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (window.hj!.q = window.hj!.q || []).push(args);
    };

  window._hjSettings = { hjid: keyHotjar, hjsv: 6 };

  script.type = 'text/javascript';
  script.src = `${hotjarEndpoint}-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;
  script.id = hotjarContainer;
  script.async = true;
  script.onload = callback;
  script.onerror = onError;

  container.appendChild(script);
}

const useProviderHotjar = (): void => {
  const app = useApp();
  const auth = useAuth();

  const [hotjarElement, setHotjarElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    (async (): Promise<() => void> => {
      Logger.debug('[HOTJAR] Entrando no useEffect');

      let intervalHotjar: NodeJS.Timeout;

      function removeHotjar(): void {
        const script = document.getElementById(hotjarContainer);
        if (script) {
          Logger.debug('[HOTJAR] Removendo hotjar scripts');
          script.remove();
          Logger.debug(`[HOTJAR] ${hotjarContainer} removido com sucesso`);
        }

        const scriptElement = document.querySelector('script[src*="https://script.hotjar.com/modules"]');
        if (scriptElement) {
          scriptElement.parentNode?.removeChild(scriptElement);
          Logger.debug('[HOTJAR] Script adicional removido com sucesso');
        }

        if (hotjarElement !== null) setHotjarElement(null);
        clearInterval(intervalHotjar);

        if (window.hj) window.hj = undefined;
        if (window._hjSettings) window._hjSettings = undefined;
        if (window.hjSiteSettings) window.hjSiteSettings = undefined;
        if (window.hjBootstrap) window.hjBootstrap = undefined;
        if (window.hjBootstrapCalled) window.hjBootstrapCalled = undefined;
        if (window.hjLazyModules) window.hjLazyModules = undefined;
        Logger.debug('[HOTJAR] window.hj functions setado para undefined');
      }

      async function checkHotjar(): Promise<void> {
        Logger.debug('[HOTJAR]', 'checkHotjar', app.module);
        const hotjarLauncher = document.getElementById(hotjarContainer);
        if (hotjarLauncher) {
          Logger.debug('[HOTJAR] Hotjar instanciado com sucesso');
          clearInterval(intervalHotjar);
          setHotjarElement(hotjarLauncher);
        }
      }

      async function loadHotjarScript(): Promise<void> {
        try {
          const callbackLoad = async (): Promise<void> => {
            intervalHotjar = setInterval(checkHotjar, 100);
            Logger.debug('[HOTJAR] Scripts carregados com sucesso');
          };
          const callbackOnError = async (): Promise<void> => {
            Logger.debug('[HOTJAR] Ocorreu um error');
            removeHotjar();
          };
          const project: HotjarTokenProjectType = app.module === 'portal' ? 'PORTAL' : 'MYAPPS';
          await loadHotjar(callbackLoad, callbackOnError, project);
        } catch (error) {
          Logger.error('[HOTJAR]', error);
          clearInterval(intervalHotjar);
        }
      }

      removeHotjar();

      if (app.module === 'my-apps' && (!auth.user?.settings?.hotjar.enabled || !['prod', 'release'].includes(stage))) {
        Logger.debug('[HOTJAR] Hotjar não deve ser habilitado ao usuário');
        return () => {};
      }
      await loadHotjarScript();

      return () => {};
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.module]);
};

export function ProviderHotjar({ children }: { children?: JSX.Element | undefined }): JSX.Element {
  useProviderHotjar();
  return children || <></>;
}

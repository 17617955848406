import { Box, Button } from '@mui/material';
import useAnalytics from 'hooks/useAnalytics';
import { makeStyles } from '@mui/styles';
import ModalContato from '../ModalContato';
import { useEffect, useState } from 'react';
import { setIsVisibleBannerComercial } from 'store/reducer/dashboard';
import { useDispatch } from 'react-redux';
import { LocalStorageService } from 'services';

const useStyles = makeStyles(() => ({
  topBannerComercial: {
    padding: '7px 10px',
    textAlign: 'center',
    background: '#FF804C',
    color: '#001E27',
    zIndex: 1,
    fontWeight: '400',
    fontFamily: 'Poppins',
    '& *': {
      fontFamily: 'Poppins',
    },
  },

  buttonTop: {
    color: ' #ffffff',
    border: '2px solid transparent',
    padding: '8px 14px',
    fontSize: '13px',
    fontWeight: '500 !important',
    lineHeight: '16px',
    borderRadius: '50px',
    textTransform: 'none',
    backgroundColor: '#001E27',
    marginLeft: '20px',
    '&:hover': {
      backgroundColor: '#001E27',
    },
  },

  closeButton: {
    color: '#001E27',
    width: '64px',
    height: '50px',
    position: 'absolute',
    right: '0px',
    top: '0px',
    display: 'flex',
    fontSize: '22px',
  },
}));

export function BannerComercial(): JSX.Element {
  const classes = useStyles();

  const dispatch = useDispatch();

  const analytics = useAnalytics('banner_contact', 'portal');

  const [modalContatoOpen, setModalContatoOpen] = useState(false);

  const click = async (): Promise<void> => {
    analytics.click('see_more');
    setModalContatoOpen(true);
  };

  const clickClose = async (): Promise<void> => {
    analytics.click('close_banner');
    dispatch(setIsVisibleBannerComercial(false));
    LocalStorageService.setDashboardBannerComercialVisible(false);
  };

  const closeModalContato = (): void => {
    setModalContatoOpen(false);
  };

  useEffect(() => {
    analytics.click('load_banner');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <b>Vá além: conheça o sistema de Gestão Omie</b>
      <Button sx={{ color: '#ffffff', marginLeft: '20px' }} className={classes.buttonTop} onClick={click}>
        Saiba mais &nbsp;&nbsp;<i className="fa fa-arrow-right"></i>
      </Button>
      <Button className={classes.closeButton} onClick={clickClose}>
      <i className="fa fa-times"></i>
      </Button>
      <ModalContato open={modalContatoOpen} handleClose={closeModalContato}></ModalContato>
    </>
  );
}

interface TopBannerHomeProps {
  isVisibleBannerComercial: boolean;
}

export default function TopBannerHome(props: TopBannerHomeProps): JSX.Element {
  const { isVisibleBannerComercial } = props;

  const classes = useStyles();

  return (
    <>
      {isVisibleBannerComercial && (
        <Box className={classes.topBannerComercial}>
          <BannerComercial />
        </Box>
      )}
    </>
  );
}

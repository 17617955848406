/* eslint-disable max-len */
import { useContext, useState, useEffect } from 'react';
import { CartContext } from 'context/CartContext';
import { IProviderCart, CartItem, ICart, CartBillData, ListCartProducts } from 'interfaces/cart';
import { Logger } from 'services';
import { UserAddress } from 'interfaces/user';
import useAuth from './useAuth';
import { getMyAddresses, postNewAddress, updateChangeAddress } from 'resources/omie';
import { getCart, getCompanyStore, postCartOrder, sendCart, shippingCalculateValue, postCartReOrder } from 'resources';
import { CompanyCache } from 'interfaces';
import { PostOrderCalculate, PostShippingCalculateValue, PostShippingCalculateValueCotacao, ResponseError, ApiResponseStatus } from 'interfaces/api';
import { CompanyStorePaymentMethod } from 'context/CompanyContext';
import CartModalDuplicate from 'components/Cart/ModalDuplicate';
import useApp from './useApp';


const useProviderCart = (): IProviderCart => {

  const [isLoading, setLoading] = useState(true);
  const [items, setItems] = useState<CartItem[]>([]);
  const [provider, setProvider] = useState<CompanyCache | null>(null);
  const [addresses, setAddresses] = useState<UserAddress[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<UserAddress | null>(null);
  const [selectedBillData, setSelectedBillData] = useState<CartBillData>(null);
  const [isSyncing, setSyncing] = useState(false);
  const [methodPaymentSelected, setMethodPaymentSelected] = useState<CompanyStorePaymentMethod['method'] | number>(0);
  const [shippingSelected, setShippingSelected] = useState<PostShippingCalculateValueCotacao | null>(null);
  const [showModalDuplicate, setShowModalDuplicate] = useState(false);
  const auth = useAuth();
  const app = useApp();

  const syncingCart = async (
    itemsToSync: CartItem[],
    providerToSync: CompanyCache | null,
    addressDeliveryToSync: UserAddress | null,
    newMethodPaymentSelectedToSync: CompanyStorePaymentMethod['method'] | number,
  ): Promise<void> => {

    if (!isSyncing) setSyncing(true);

    const oldItems = JSON.parse(JSON.stringify(items));
    setItems(itemsToSync);

    try {
      await sendCart({
        app_hash: (providerToSync) ? providerToSync.app_hash : null,
        addressDelivery: addressDeliveryToSync,
        billDataPayment: selectedBillData,
        paymentMethod: newMethodPaymentSelectedToSync,
        items: itemsToSync,
      });
      if (itemsToSync.length > 0) {
        if (provider?.app_hash !== providerToSync?.app_hash) {
          setProvider(providerToSync);
        }
      } else {
        setProvider(null);
      }
      if (methodPaymentSelected !== newMethodPaymentSelectedToSync) {
        setMethodPaymentSelected(newMethodPaymentSelectedToSync);
      }
    } catch (error) {
      Logger.error(error);
      // se der erro fazemos um "rollback nos states rs
      setItems(oldItems);
    }

    setSyncing(false);
  };

  const syncingLocalCart = async (): Promise<void> => {
    if (!isSyncing) setSyncing(true);
    await getCart()
      .then(async ({ data }) => {
        if (data.status === 'success') {
          const cartResponse = data.cart as ICart | null;
          if (cartResponse?.addressDelivery) setSelectedAddress(cartResponse.addressDelivery);
          if (cartResponse?.paymentMethod) setMethodPaymentSelected(cartResponse.paymentMethod);
          if (cartResponse && cartResponse.app_hash) {
            await getCompanyStore(cartResponse.app_hash, false)
              .then((dataCompany) => {
                if (dataCompany.status === 'success') {
                  setProvider(dataCompany as CompanyCache);
                  setItems(cartResponse.items as CartItem[]);
                  setSelectedBillData(cartResponse.billDataPayment);
                } else {
                  setProvider(null);
                  setItems([]);
                  setSelectedBillData(null);
                }
              })
              .catch(() => {
                setProvider(null);
                setItems([]);
                setSelectedBillData(null);
              });
          } else {
            setProvider(null);
            setItems([]);
            setSelectedBillData(null);
          }
        } else {
          setProvider(null);
          setItems([]);
          setSelectedBillData(null);
          Logger.error('Não foi possível buscar o carrinho');
          Logger.error(data);
        }
      })
      .catch((error) => {
        Logger.error(error);
      });
    setSyncing(false);
  };

  const deleteItem = async (productAlias: CartItem['product_alias']): Promise<void> => {
    const itemIndex = items.findIndex(item => item.product_alias === productAlias);
    if (itemIndex < 0) {
      Logger.error('Produto não localizado no carrinho', productAlias);
      return;
    }

    const itemUpdated = JSON.parse(JSON.stringify(items));
    itemUpdated.splice(itemIndex, 1);

    await syncingCart(itemUpdated, provider, selectedAddress, methodPaymentSelected);
  };

  const deleteUnavailableItem = async (): Promise<void> => {
    const itemUpdated: CartItem[] = JSON.parse(JSON.stringify(items));

    const filtered = itemUpdated.filter((item) => item.is_available === true);

    await syncingCart(filtered, provider, selectedAddress, methodPaymentSelected);
  };

  const changeItemQuantity = async (productAlias: CartItem['product_alias'], newQuantity: 1 | -1 | number | undefined,  qntdInput: number): Promise<void> => {
    const itemIndex = items.findIndex(item => item.product_alias === productAlias);
    if (itemIndex < 0) {
      Logger.error('Produto não localizado no carrinho', productAlias);
      return;
    }

    const itemUpdated: CartItem[] = JSON.parse(JSON.stringify(items));

    if (newQuantity === undefined) {
      itemUpdated[itemIndex].quantity = qntdInput;
    } else {
      itemUpdated[itemIndex].quantity += newQuantity;
    }

    const productItem = itemUpdated[itemIndex];

    if (productItem.quantity === 0) {
      Logger.info('Item zerado inválido, deve-se excluir');
      return deleteItem(productAlias);
    }

    if (provider?.store.stock) {
      if (productItem.stock) {
        if (productItem.stock.qty < productItem.quantity && (newQuantity == 1 || newQuantity as number > 0 || qntdInput >= 1)) {
          //Logger.info('Quantidade acima do estoque definido');
          throw Error('Quantidade acima do estoque definido');
        }
      }
    }

    await syncingCart(itemUpdated, provider, selectedAddress, methodPaymentSelected);
  };

  const addItem = async (newItem: CartItem, newProvider: CompanyCache): Promise<void> => {

    if (items.length > 0) {

      if (provider !== null && newProvider.app_hash !== provider.app_hash) {
        setShowModalDuplicate(true);
        throw Error('Providers duplicates');
      }

      const itemIndex = items.findIndex(item => item.product_alias === newItem.product_alias);
      if (itemIndex >= 0) {
        return changeItemQuantity(newItem.product_alias, newItem.quantity, 1);
      }
    }

    await syncingCart([...items, newItem], newProvider, selectedAddress, methodPaymentSelected);
  };

  const reOrder = async (link_order: string, app_hash: string): Promise<ApiResponseStatus | ResponseError> => {

    if (provider !== null && app_hash !== provider.app_hash) {
      setShowModalDuplicate(true);
      throw Error('Providers duplicates');
    }

    return postCartReOrder(link_order);
    
  };

  const clearAllItems = async (): Promise<void> => {
    await syncingCart([], null, selectedAddress, 0);
  };

  const addAddress = async (newAddress: UserAddress): Promise<UserAddress> => {
    return postNewAddress(newAddress)
      .then(({ data }) => {
        if (data.msg === 'ok') {
          const addressWithId = {
            ...newAddress,
            address_id: data.address_id,
          };
          setAddresses([...addresses, addressWithId]);
          return addressWithId;
        }
        throw new Error('API ERROR');
      })
      .catch((error) => {
        throw error;
      });
  };

  const updateAddress = async (addressToUpdate: UserAddress): Promise<void> => {
    const addressesNew: UserAddress[] = JSON.parse(JSON.stringify(addresses));
    const filtered = addressesNew.findIndex((userAddress) => userAddress.address_id === addressToUpdate.address_id);
    if (filtered >= 0) {
      const addressOld = { ...addressesNew[filtered] } as UserAddress;
      let hasChanges = false;
      for (const key in addressOld) {
        if (addressOld[key] !== addressToUpdate[key]) {
          hasChanges = true;
          break;
        }
      }
      if (hasChanges) {
        await updateChangeAddress(addressToUpdate);
        addressesNew[filtered] = addressToUpdate;
        setAddresses(addressesNew);
      }
    }
  };

  const changeSelectedAddress = async (address: UserAddress): Promise<void> => {

    const filtered = addresses.filter((userAddress) => userAddress.address_zipcode.replace('-', '') === address.address_zipcode);
    if (filtered.length > 0) {
      setSelectedAddress(filtered[0]);
      await syncingCart(items, provider, filtered[0], methodPaymentSelected);
    } else {
      setSelectedAddress(address);
      await syncingCart(items, provider, address, methodPaymentSelected);
    }


  };

  const changeSelectedBillData = async (newSelectedBillData: CartBillData): Promise<void> => {
    setSelectedBillData(newSelectedBillData);
    // await syncingCart(items, provider, selectedAddress, methodPaymentSelected);
  };

  useEffect(() => {
    (async (): Promise<void> => {

      const getAddress = async (): Promise<void> => {
        // buscar endereços if logado
        if (auth.isAuthenticated && auth.user) {
          await getMyAddresses()
            .then(async (response) => {
              const addressesUser = response.data;
              setAddresses(addressesUser);
            })
            .catch((error) => {
              Logger.error(error);
            });
        }
      };

      if (app.module === 'portal') {
        await Promise.all([
          getAddress(),
          syncingLocalCart(),
        ]);
      }

      setSyncing(false);
      setLoading(false);

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.module]);

  const shippingCalculate = async (zipcode: string): Promise<PostShippingCalculateValue['result'] | null> => {

    if (!provider) return null;
    if (!provider.endereco.cep) return null;

    if (!isSyncing) setSyncing(true);

    const newMap: ListCartProducts[] = items.map(item => {
      return {
        nIdProd: item.product_id,
        nQtde: item.quantity,
      };
    });

    const response = await shippingCalculateValue(provider.app_hash, zipcode, newMap);
    if (response.status === 'success') {
      const shippingResponse = response.result as PostShippingCalculateValue['result'];

      if (shippingResponse.status === true && shippingResponse.cotacao.length > 0) {
        setShippingSelected(shippingResponse.cotacao[0]);
      } else {
        if (shippingResponse !== null) {
          setShippingSelected(null);
        }
      }

      setSyncing(false);
      return shippingResponse;
    }

    setSyncing(false);
    return null;
  };

  const changeMethodPaymentSelected = async (newMethodPaymentSelected: CompanyStorePaymentMethod['method'] | number): Promise<void> => {
    await syncingCart(items, provider, selectedAddress, newMethodPaymentSelected);
  };

  const sendOrder = async (isSimulate: boolean): Promise<PostOrderCalculate | ResponseError> => {

    const shipping = (shippingSelected !== null)
      ? shippingSelected
      : {
        'descricao_servico': 'Correios PAC',
        'dias': 0,
        'metodo_envio': 'STANDARD',
        'servico': 'Correios PAC',
        'valor': 0,
      };

    const payload = {
      items: items,
      addressDelivery: selectedAddress,
      billDataPayment: selectedBillData,
      paymentMethod: methodPaymentSelected,
      app_hash: provider?.app_hash as string,
      is_simulate: isSimulate,
      shipping: shipping,
    };

    return postCartOrder(payload);
  };

  const toggleModalDuplicate = (): void => {
    setShowModalDuplicate(!showModalDuplicate);
  };

  return {
    isLoading,
    items,
    provider,
    addItem,
    reOrder,
    changeItemQuantity,
    deleteItem,
    deleteUnavailableItem,
    clearAllItems,
    addAddress,
    updateAddress,
    addresses,
    selectedAddress,
    selectedBillData,
    changeSelectedBillData,
    changeSelectedAddress,
    isSyncing,
    syncingLocalCart,
    shippingCalculate,
    setMethodPaymentSelected: changeMethodPaymentSelected,
    methodPaymentSelected,
    sendOrder,
    showModalDuplicate,
    toggleModalDuplicate,
  };

};

export function ProviderCart({ children }: { children: JSX.Element }): JSX.Element {
  const cart = useProviderCart();

  return (
    <CartContext.Provider value={cart}>
      {children}
      {
        (cart.showModalDuplicate) && <CartModalDuplicate onClose={cart.toggleModalDuplicate} />
      }
    </CartContext.Provider>
  );
}

const useCart = (): IProviderCart => useContext(CartContext);
export default useCart;
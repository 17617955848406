import axios from 'axios';
import { stage } from 'services';
import { IApiConfig } from './interfaces';

const configs: IApiConfig = {
  prod: {
    baseURL: 'https://api.ergoncredit.com.br',
  },
  release: {
    baseURL: 'https://api.ergoncredit.com.br',
  },
  dsv: {
    baseURL: 'https://homologacao.ergoncredit.com.br',
  },
};

const configApi = configs[stage];

const apiErgon = axios.create({
  baseURL: configApi.baseURL,
});

export { apiErgon };
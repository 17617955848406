import React from 'react';
import { Typography } from '@mui/material';
import NavGroup from './NavGroup';
import useApp from 'hooks/useApp';
import useCart from 'hooks/useCart';
import { MenuItemGroup } from 'interfaces/menu-items';

const MenuList = (): JSX.Element => {

  const app = useApp();
  const cart = useCart();

  const menuItems: MenuItemGroup[] = JSON.parse(JSON.stringify(app.menu.items));
  if (cart.items.length > 0) {
    menuItems[0].children?.push({
      title: 'Carrinho',
      type: 'item',
      url: '/meu-carrinho',
      icon: 'cart-shopping',
      breadcrumbs: false,
      analytics: 'cart'
    });
  }

  const navItems = menuItems.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;

import { useEffect, useState } from 'react';
import { Logger, api } from 'services';

interface GetPingResponse {
  status: 'success',
  version: string,
}

export default function VersionControl(): null {

  const [currentVersion, setVersion] = useState<string | null>(null);

  const runAsync = async (): Promise<void> => {
    const response = await api.get<GetPingResponse>('/ping');
    if (response.data.status === 'success') {

      const version = response.data.version;
      Logger.debug('[VERSION]', 'Versão recebida da api:', version);
      Logger.debug('[VERSION]', 'Versão atual', currentVersion);

      if (currentVersion === null) {
        setVersion(version);
        return;
      }

      if (currentVersion !== version) {
        Logger.debug('[VERSION]', 'Forçando reload');
        window.location.reload();
        return;
      }

    }
  };

  useEffect(() => {
    runAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval: any | null = null;
    if (currentVersion !== null) {
      // verificação a cada 2 horas
      interval = setInterval(runAsync, 2 * 60 * 60000);
      // interval = setInterval(runAsync, 10000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVersion]);

  Logger.debug('[VERSION]', 'Version Control:', currentVersion);

  return null;
}